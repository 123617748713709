    import React, { useState } from 'react';
    import { API } from '../../service/api';
    import { useParams ,useNavigate} from 'react-router-dom';
    import {styled,Typography} from '@mui/material';
    const Error = styled(Typography)`
    font-size: 10px;
    color: #ff6161;
    line-height: 0;
    margin-top: 10px;
    font-weight: 600;
`;

    const UserDetailsForm = () => {
    const { prices } = useParams();
    const [error, showError] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        transactionId: '',
        username: '',
        name: '',
        phoneNumber: ''
    });
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleSubmit = async () => {
        try {
            // Wait for the API call to complete and get the response
            let response = await API.userPaymentdetail(formData);
    
            // Check if the login was successful
            if (response.isSuccess) {
                // Reset error message
                showError('');
    
                
                navigate('/');
            } else {
                // Display error message
                showError('Something went wrong! Please Cheack Your Username. Please try again later.');
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            showError('An error occurred. Please try again later.');
            console.error('Error during login:', error);
        }
    };

    return (
        <div>
        <h2>Scane and Pay {prices} for </h2>
        <form onSubmit={handleSubmit}>
            <div>
            <img src="/hiregpyqrcode.png" alt="Sample Image" />
            </div>
            <div>
            {error && <Error>Error: {error}</Error>}
            </div>
            <div>
            <label>
                UPI Transaction ID:
                <input
                type="text"
                name="transactionId"
                value={formData.transactionId}
                onChange={handleInputChange}
                required
                />
            </label>
            </div>
            <div>
            <label>
                Username:
                <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
                />
            </label>
            </div>
            <div>
            <label>
                Name:
                <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                />
            </label>
            </div>
            <div>
            <label>
                Phone Number:
                <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                />
            </label>
            </div>
            <div>
            <label>
            <p>Pay Price for : {prices}</p>
                
            </label>
            </div>
            <button type="submit">Submit</button>
        </form>
        </div>
    );
    };

    export default UserDetailsForm;
