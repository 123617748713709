// import { styled, Box, Typography, InputAdornment, IconButton, TextField } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';

// const Image = styled(Box)`
//     width: 100%;
//     background: url(https://images.pexels.com/photos/1714208/pexels-photo-1714208.jpeg) center/55% repeat-x #000;
//     height: 75vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     border-radius: 30px;
// `;

// const Heading = styled(Typography)`
//     font-size: 70px;
//     color: #FFFFFF;
//     line-height: 1;
// `;

// const SubHeading = styled(Typography)`
//     font-size: 20px;
//     background: #FFFFFF;
//     border-radius: 15px;
//     padding: 10px;
// `;

// const SearchInput = styled(TextField)`
//     width: 300px;
//     margin-top: 75px;
//     & .MuiOutlinedInput-root {
//         border-radius: 15px;
//         & fieldset {
//             border-color: none;
//         }
//         &:hover fieldset {
//             border-color: yellow;
//         }
//         &.Mui-focused fieldset {
//             border-color: pink;
//         }
        
//     }
//     & .MuiInputBase-input {
//         color: white;
//     }
// `;

// const Banner = () => {
//     const handleSearch = (event) => {
//         // Implement your search logic here
//         const inputValue = event.target.value;
//         // You can call a function or perform other actions with the search input value
//         console.log("Search input:", inputValue);
//     };

//     return (
//         <Image>
//             <Heading>Stockimia</Heading>
//             {/* <SubHeading>Search Stock</SubHeading> */}
//             <SearchInput
//                 placeholder="Search stock"
//                 variant="outlined"
//                 InputProps={{
//                     startAdornment: (
//                         <InputAdornment position="start">
//                             <IconButton>
//                                 <SearchIcon style={{ color: 'yellow' }} />
//                             </IconButton>
//                         </InputAdornment>
//                     ),
//                 }}
//                 onChange={handleSearch}
//             />
//         </Image>
//     );
// };

// export default Banner;
import React, { useState } from "react";
import { styled, Box, Typography, Autocomplete, TextField } from '@mui/material';

import { Link } from "react-router-dom";
import "./banner.css";
const Image = styled(Box)`
    width: 100%;
    background: url(https://images.pexels.com/photos/1714208/pexels-photo-1714208.jpeg) center/55% repeat-x #000;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
`;

const Heading = styled(Typography)`
    font-size: 70px;
    color: #FFFFFF;
    line-height: 1;
`;





const Banner = () => {

    const [stock, setStock] = useState("");
    
    

    const handleInputChange = (event, value) => {
        // setStockName(event.target.value);
        setStock(value ? value.label : ""); // Assuming value is an object with a 'label' property
        
    };
    
    
    
    const top100Films = [
    
        // { label: "Nifty_50", year: 1998 },
        // { label: "Nifty_Bank", year: 1998 },
        // { label: "Sensex", year: 1998 },
        // { label: "Nifty_500", year: 1998 },
        // { label: "NIFTY_FIN_SERVICE", year: 1998 },
        // { label: "BHNL", year: 1998 },
        // { label: "BTC / INR", year: 1998 },
        { label: "3MINDIA", year: 1998 },
        { label: "ABB", year: 1998 },
        { label: "ACC", year: 1998 },
        { label: "ADFFOODS", year: 1998 },
        { label: "AIAENG", year: 1998 },
        { label: "APLAPOLLO", year: 1998 },
        { label: "AUBANK", year: 1998 },
        { label: "AARTIDRUGS", year: 1998 },
        { label: "AARTIIND", year: 1998 },
        { label: "AAVAS", year: 1998 },
        { label: "ABBOTINDIA", year: 1998 },
        { label: "ACCELYA", year: 1998 },
        { label: "ACE", year: 1998 },
        { label: "ADANIENT", year: 1998 },
        { label: "ADANIGREEN", year: 1998 },
        { label: "ADANIPORTS", year: 1998 },
        { label: "ATGL", year: 1998 },
        { label: "ADANITRANS", year: 1998 },
        { label: "ABCAPITAL", year: 1998 },
        { label: "ABFRL", year: 1998 },
        { label: "ADVENZYMES", year: 1998 },
        { label: "AEGISCHEM", year: 1998 },
        { label: "AFFLE", year: 1998 },
        { label: "ATFL", year: 1998 },
        { label: "AJANTPHARM", year: 1998 },
        { label: "ALEMBICLTD", year: 1998 },
        { label: "APLLTD", year: 1998 },
        { label: "ALKEM", year: 1998 },
        { label: "ALKYLAMINE", year: 1998 },
        { label: "ALLCARGO", year: 1998 },
        { label: "ALOKINDS", year: 1998 },
        { label: "AMARAJABAT", year: 1998 },
        { label: "AMBER", year: 1998 },
        { label: "AMBUJACEM", year: 1998 },
        { label: "AMRUTANJAN", year: 1998 },
        { label: "ANANTRAJ", year: 1998 },
        { label: "ANDHRSUGAR", year: 1998 },
        { label: "ANGELONE", year: 1998 },
        { label: "ANURAS", year: 1998 },
        { label: "APARINDS", year: 1998 },
        { label: "APCOTEXIND", year: 1998 },
        { label: "APOLLOHOSP", year: 1998 },
        { label: "APOLLOTYRE", year: 1998 },
        { label: "ARVINDFASN", year: 1998 },
        { label: "ARVIND", year: 1998 },
        { label: "ASAHIINDIA", year: 1998 },
        { label: "ASHIANA", year: 1998 },
        { label: "ASHOKLEY", year: 1998 },
        { label: "ASHOKA", year: 1998 },
        { label: "ASIANPAINT", year: 1998 },
        { label: "ASTEC", year: 1998 },
        { label: "ASTERDM", year: 1998 },
        { label: "ASTRAMICRO", year: 1998 },
        { label: "ASTRAZEN", year: 1998 },
        { label: "ASTRAL", year: 1998 },
        { label: "ATUL", year: 1998 },
        { label: "AUROPHARMA", year: 1998 },
        { label: "AUTOAXLES", year: 1998 },
        { label: "AVANTIFEED", year: 1998 },
        { label: "DMART", year: 1998 },
        { label: "AXISBANK", year: 1998 },
        { label: "BASF", year: 1998 },
        { label: "BEML", year: 1998 },
        { label: "BFINVEST", year: 1998 },
        { label: "BFUTILITIE", year: 1998 },
        { label: "BLS", year: 1998 },
        { label: "BSE", year: 1998 },
        { label: "BAJAJ-AUTO", year: 1998 },
        { label: "BAJAJCON", year: 1998 },
        { label: "BAJAJELEC", year: 1998 },
        { label: "BAJFINANCE", year: 1998 },
        { label: "BAJAJFINSV", year: 1998 },
        { label: "BAJAJHIND", year: 1998 },
        { label: "BAJAJHLDNG", year: 1998 },
        { label: "BALAMINES", year: 1998 },
        { label: "BALKRISIND", year: 1998 },
        { label: "BALMLAWRIE", year: 1998 },
        { label: "BALRAMCHIN", year: 1998 },
        { label: "BANCOINDIA", year: 1998 },
        { label: "BANDHANBNK", year: 1998 },
        { label: "BANKBARODA", year: 1998 },
        { label: "BANKINDIA", year: 1998 },
        { label: "MAHABANK", year: 1998 },
        { label: "BARBEQUE", year: 1998 },
        { label: "BATAINDIA", year: 1998 },
        { label: "BAYERCROP", year: 1998 },
        { label: "BERGEPAINT", year: 1998 },
        { label: "BEPL", year: 1998 },
        { label: "BDL", year: 1998 },
        { label: "BEL", year: 1998 },
  { label: "BHARATFORG", year: 1998 },
  { label: "BHEL", year: 1998 },
  { label: "BPCL", year: 1998 },
  { label: "BHARATRAS", year: 1998 },
  { label: "BHARTIARTL", year: 1998 },
  { label: "BIOCON", year: 1998 },
  { label: "BIRLACORPN", year: 1998 },
  { label: "BSOFT", year: 1998 },
  { label: "BLISSGVS", year: 1998 },
  { label: "BLUEDART", year: 1998 },
  { label: "BLUESTARCO", year: 1998 },
  { label: "BODALCHEM", year: 1998 },
  { label: "BBTC", year: 1998 },
  { label: "BOMDYEING", year: 1998 },
  { label: "BOROLTD", year: 1998 },
  { label: "BORORENEW", year: 1998 },
  { label: "BOSCHLTD", year: 1998 },
  { label: "BRIGADE", year: 1998 },
  { label: "BRITANNIA", year: 1998 },
  { label: "BURGERKING", year: 1998 },
  { label: "CARERATING", year: 1998 },
  { label: "CCL", year: 1998 },
  { label: "CESC", year: 1998 },
  { label: "CGPOWER", year: 1998 },
  { label: "CRISIL", year: 1998 },
  { label: "CSBBANK", year: 1998 },
  { label: "CADILAHC", year: 1998 },
  { label: "CAMLINFINE", year: 1998 },
  { label: "CANFINHOME", year: 1998 },
  { label: "CANBK", year: 1998 },
  { label: "CAPACITE", year: 1998 },
  { label: "CAPLIPOINT", year: 1998 },
  { label: "CGCL", year: 1998 },
  { label: "CARBORUNIV", year: 1998 },
  { label: "CASTROLIND", year: 1998 },
  { label: "CEATLTD", year: 1998 },
  { label: "CENTRALBK", year: 1998 },
  { label: "CDSL", year: 1998 },
  { label: "CENTRUM", year: 1998 },
  { label: "CENTURYPLY", year: 1998 },
  { label: "CENTURYTEX", year: 1998 },
  { label: "CERA", year: 1998 },
  { label: "CHALET", year: 1998 },
  { label: "CHAMBLFERT", year: 1998 },
  { label: "CHEMCON", year: 1998 },
  { label: "CHENNPETRO", year: 1998 },
  { label: "CHOLAHLDNG", year: 1998 },
  { label: "CHOLAFIN", year: 1998 },
  { label: "CIGNITITEC", year: 1998 },
  { label: "CIPLA", year: 1998 },
  { label: "CUB", year: 1998 },
  { label: "CLNINDIA", year: 1998 },
  { label: "COALINDIA", year: 1998 },
  { label: "COCHINSHIP", year: 1998 },
  { label: "COFORGE", year: 1998 },
  { label: "COLPAL", year: 1998 },
  { label: "CAMS", year: 1998 },
  { label: "CONFIPET", year: 1998 },
  { label: "CONCOR", year: 1998 },
  { label: "COROMANDEL", year: 1998 },
  { label: "COSMOFILMS", year: 1998 },
  { label: "CRAFTSMAN", year: 1998 },
  { label: "CREDITACC", year: 1998 },
  { label: "CROMPTON", year: 1998 },
  { label: "CUMMINSIND", year: 1998 },
  { label: "CYIENT", year: 1998 },
  { label: "DBCORP", year: 1998 },
  { label: "DCBBANK", year: 1998 },
  { label: "DCMSHRIRAM", year: 1998 },
  { label: "DFMFOODS", year: 1998 },
  { label: "DLF", year: 1998 },
  { label: "DABUR", year: 1998 },
  { label: "DALBHARAT", year: 1998 },
  { label: "DALMIASUG", year: 1998 },
  { label: "DEEPAKFERT", year: 1998 },
  { label: "DEEPAKNTR", year: 1998 },
  { label: "DELTACORP", year: 1998 },
  { label: "DEN", year: 1998 },
  { label: "DHAMPURSUG", year: 1998 },
  { label: "DHANI", year: 1998 },
  { label: "DHANUKA", year: 1998 },
  { label: "DBL", year: 1998 },
  { label: "DISHTV", year: 1998 },
  { label: "DCAL", year: 1998 },
  { label: "DIVISLAB", year: 1998 },
  { label: "DIXON", year: 1998 },
  { label: "DOLATALGO", year: 1998 },
  { label: "DOLLAR", year: 1998 },
  { label: "LALPATHLAB", year: 1998 },
  { label: "DRREDDY", year: 1998 },
  { label: "DREDGECORP", year: 1998 },
  { label: "EIDPARRY", year: 1998 },
  { label: "EIHOTEL", year: 1998 },
  { label: "EPL", year: 1998 },
  { label: "ESABINDIA", year: 1998 },
  { label: "EASEMYTRIP", year: 1998 },
  { label: "EDELWEISS", year: 1998 },
  { label: "EICHERMOT", year: 1998 },
  { label: "ELECTCAST", year: 1998 },
  { label: "ELGIEQUIP", year: 1998 },
  { label: "EMAMILTD", year: 1998 },
  { label: "ENDURANCE", year: 1998 },
  { label: "ENGINERSIN", year: 1998 },
  { label: "EQUITAS", year: 1998 },
  { label: "EQUITASBNK", year: 1998 },
  { label: "ERIS", year: 1998 },
  { label: "ESCORTS", year: 1998 },
  { label: "EVEREADY", year: 1998 },
  { label: "EXCELINDUS", year: 1998 },
  { label: "EXIDEIND", year: 1998 },
  { label: "FDC", year: 1998 },
  { label: "FEDERALBNK", year: 1998 },
  { label: "FMGOETZE", year: 1998 },
  { label: "FACT", year: 1998 },
  { label: "FILATEX", year: 1998 },
  { label: "FINEORG", year: 1998 },
  { label: "FINCABLES", year: 1998 },
  { label: "FINPIPE", year: 1998 },
  { label: "FSL", year: 1998 },
  { label: "FORCEMOT", year: 1998 },
  { label: "FORTIS", year: 1998 },
  { label: "FCONSUMER", year: 1998 },
  { label: "FRETAIL", year: 1998 },
  { label: "GMBREW", year: 1998 },
  { label: "GAIL", year: 1998 },
  { label: "GEPIL", year: 1998 },
  { label: "GET&D", year: 1998 },
  { label: "GMMPFAUDLR", year: 1998 },
  { label: "GTPL", year: 1998 },
  { label: "GABRIEL", year: 1998 },
  { label: "GALAXYSURF", year: 1998 },
  { label: "GRSE", year: 1998 },
  { label: "GARFIBRES", year: 1998 },
  { label: "GATI", year: 1998 },
  { label: "GICRE", year: 1998 },
  { label: "GENUSPOWER", year: 1998 },
  { label: "GEOJITFSL", year: 1998 },
  { label: "GILLETTE", year: 1998 },
  { label: "GLAND", year: 1998 },
  { label: "GLAXO", year: 1998 },
  { label: "GLENMARK", year: 1998 },
  { label: "GPIL", year: 1998 },
  { label: "GODFRYPHLP", year: 1998 },
  { label: "GODREJAGRO", year: 1998 },
  { label: "GODREJCP", year: 1998 },
  { label: "GODREJIND", year: 1998 },
  { label: "GODREJPROP", year: 1998 },
  { label: "GOODYEAR", year: 1998 },
  { label: "GRANULES", year: 1998 },
  { label: "GRAPHITE", year: 1998 },
  { label: "GRASIM", year: 1998 },
  { label: "GESHIP", year: 1998 },
  { label: "GREAVESCOT", year: 1998 },
  { label: "GREENPANEL", year: 1998 },
  { label: "GREENPLY", year: 1998 },
  { label: "GRINDWELL", year: 1998 },
  { label: "GUJALKALI", year: 1998 },
  { label: "GAEL", year: 1998 },
  { label: "FLUOROCHEM", year: 1998 },
  { label: "GUJGASLTD", year: 1998 },
  { label: "GIPCL", year: 1998 },
  { label: "GMDCLTD", year: 1998 },
  { label: "GNFC", year: 1998 },
  { label: "GPPL", year: 1998 },
  { label: "GSFC", year: 1998 },
  { label: "GSPL", year: 1998 },
  { label: "GULFOILLUB", year: 1998 },
  { label: "HEG", year: 1998 },
  { label: "HGINFRA", year: 1998 },
  { label: "HBLPOWER", year: 1998 },
  { label: "HCLTECH", year: 1998 },
  { label: "HDFCAMC", year: 1998 },
  { label: "HDFCBANK", year: 1998 },
  { label: "HDFCLIFE", year: 1998 },
  { label: "HFCL", year: 1998 },
  { label: "HIL", year: 1998 },
  { label: "HLEGLAS", year: 1998 },
  { label: "HSIL", year: 1998 },
  { label: "HAPPSTMNDS", year: 1998 },
  { label: "HATHWAY", year: 1998 },
  { label: "HATSUN", year: 1998 },
  { label: "HAVELLS", year: 1998 },
  { label: "HCG", year: 1998 },
  { label: "HEIDELBERG", year: 1998 },
  { label: "HEMIPROP", year: 1998 },
  { label: "HERANBA", year: 1998 },
  { label: "HERITGFOOD", year: 1998 },
  { label: "HEROMOTOCO", year: 1998 },
  { label: "HESTERBIO", year: 1998 },
  { label: "HIKAL", year: 1998 },
  { label: "HSCL", year: 1998 },
  { label: "HIMATSEIDE", year: 1998 },
  { label: "HINDALCO", year: 1998 },
  { label: "HGS", year: 1998 },
  { label: "HAL", year: 1998 },
  { label: "HCC", year: 1998 },
  { label: "HINDCOPPER", year: 1998 },
  { label: "HNDFDS", year: 1998 },
  { label: "HINDOILEXP", year: 1998 },
  { label: "HINDPETRO", year: 1998 },
  { label: "HINDUNILVR", year: 1998 },
  { label: "HINDZINC", year: 1998 },
  { label: "POWERINDIA", year: 1998 },
  { label: "HOMEFIRST", year: 1998 },
  { label: "HONAUT", year: 1998 },
  { label: "HUDCO", year: 1998 },
  { label: "HDFC", year: 1998 },
  { label: "HUHTAMAKI", year: 1998 },
  { label: "IGPL", year: 1998 },
  { label: "ICICIBANK", year: 1998 },
  { label: "ICICIGI", year: 1998 },
  { label: "ICICIPRULI", year: 1998 },
  { label: "ISEC", year: 1998 },
  { label: "ICRA", year: 1998 },
  { label: "IDBI", year: 1998 },
  { label: "IDFCFIRSTB", year: 1998 },
  { label: "IDFC", year: 1998 },
  { label: "IFBIND", year: 1998 },
  { label: "IFCI", year: 1998 },
  { label: "IIFL", year: 1998 },
  { label: "IIFLSEC", year: 1998 },
  { label: "IIFLWAM", year: 1998 },
  { label: "INEOSSTYRO", year: 1998 },
  { label: "IOLCP", year: 1998 },
  { label: "IRB", year: 1998 },
  { label: "IRCON", year: 1998 },
  { label: "ITC", year: 1998 },
  { label: "ITDCEM", year: 1998 },
  { label: "ITI", year: 1998 },
  { label: "IGARASHI", year: 1998 },
  { label: "INDIACEM", year: 1998 },
  { label: "INDIAGLYCO", year: 1998 },
  { label: "ITDC", year: 1998 },
  { label: "IBULHSGFIN", year: 1998 },
  { label: "IBREALEST", year: 1998 },
  { label: "INDIAMART", year: 1998 },
  { label: "INDIANB", year: 1998 },
  { label: "IEX", year: 1998 },
  { label: "INDHOTEL", year: 1998 },
  { label: "INDIANHUME", year: 1998 },
  { label: "IMFA", year: 1998 },
  { label: "IOC", year: 1998 },
  { label: "IOB", year: 1998 },
  { label: "IRCTC", year: 1998 },
  { label: "IRFC", year: 1998 },
  { label: "INDIGOPNTS", year: 1998 },
  { label: "ICIL", year: 1998 },
  { label: "INDOSTAR", year: 1998 },
  { label: "INDOCO", year: 1998 },
  { label: "IGL", year: 1998 },
  { label: "INDUSTOWER", year: 1998 },
  { label: "INDUSINDBK", year: 1998 },
  { label: "INFIBEAM", year: 1998 },
  { label: "NAUKRI", year: 1998 },
  { label: "INFY", year: 1998 },
  { label: "INGERRAND", year: 1998 },
  { label: "INOXLEISUR", year: 1998 },
  { label: "INOXWIND", year: 1998 },
  { label: "INSECTICID", year: 1998 },
  { label: "INTELLECT", year: 1998 },
  { label: "INDIGO", year: 1998 },
  { label: "IPCALAB", year: 1998 },
  { label: "ISGEC", year: 1998 },
  { label: "JBCHEPHARM", year: 1998 },
  { label: "JKCEMENT", year: 1998 },
  { label: "JKIL", year: 1998 },
  { label: "JBMA", year: 1998 },
  { label: "JKLAKSHMI", year: 1998 },
  { label: "JKPAPER", year: 1998 },
  { label: "JKTYRE", year: 1998 },
  { label: "JMFINANCIL", year: 1998 },
  { label: "JMCPROJECT", year: 1998 },
  { label: "JSWENERGY", year: 1998 },
  { label: "JSWISPL", year: 1998 },
  { label: "JSWSTEEL", year: 1998 },
  { label: "JTEKTINDIA", year: 1998 },
  { label: "JAGRAN", year: 1998 },
  { label: "JAICORPLTD", year: 1998 },
  { label: "JPASSOCIAT", year: 1998 },
  { label: "JPPOWER", year: 1998 },
  { label: "J&KBANK", year: 1998 },
  { label: "JAMNAAUTO", year: 1998 },
  { label: "JINDALPOLY", year: 1998 },
  { label: "JINDALSAW", year: 1998 },
  { label: "JSLHISAR", year: 1998 },
  { label: "JSL", year: 1998 },
  { label: "JINDALSTEL", year: 1998 },
  { label: "JINDWORLD", year: 1998 },
  { label: "JCHAC", year: 1998 },
  { label: "JUBLFOOD", year: 1998 },
  { label: "JUBLINGREA", year: 1998 },
  { label: "JUBLPHARMA", year: 1998 },
  { label: "JUSTDIAL", year: 1998 },
  { label: "JYOTHYLAB", year: 1998 },
  { label: "KPRMILL", year: 1998 },
  { label: "KCP", year: 1998 },
  { label: "KEI", year: 1998 },
  { label: "KNRCON", year: 1998 },
  { label: "KPITTECH", year: 1998 },
  { label: "KRBL", year: 1998 },
  { label: "KSB", year: 1998 },
  { label: "KAJARIACER", year: 1998 },
  { label: "KALPATPOWR", year: 1998 },
  { label: "KALYANKJIL", year: 1998 },
  { label: "KSL", year: 1998 },
  { label: "KANSAINER", year: 1998 },
  { label: "KTKBANK", year: 1998 },
  { label: "KARURVYSYA", year: 1998 },
  { label: "KSCL", year: 1998 },
  { label: "KEC", year: 1998 },
  { label: "KIRIINDUS", year: 1998 },
  { label: "KIRLOSBROS", year: 1998 },
  { label: "KIRLFER", year: 1998 },
  { label: "KIRLOSENG", year: 1998 },
  { label: "KOLTEPATIL", year: 1998 },
  { label: "KOTAKBANK", year: 1998 },
  { label: "L&TFH", year: 1998 },
  { label: "LTTS", year: 1998 },
  { label: "LGBBROSLTD", year: 1998 },
  { label: "LICHSGFIN", year: 1998 },
  { label: "DAAWAT", year: 1998 },
  { label: "LAOPALA", year: 1998 },
  { label: "LAXMIMACH", year: 1998 },
  { label: "LTI", year: 1998 },
  { label: "LT", year: 1998 },
  { label: "LAURUSLABS", year: 1998 },
  { label: "LXCHEM", year: 1998 },
  { label: "LEMONTREE", year: 1998 },
  { label: "LINDEINDIA", year: 1998 },
  { label: "LUMAXTECH", year: 1998 },
  { label: "LUPIN", year: 1998 },
  { label: "LUXIND", year: 1998 },
  { label: "MASFIN", year: 1998 },
  { label: "MMTC", year: 1998 },
  { label: "MOIL", year: 1998 },
  { label: "MRF", year: 1998 },
  { label: "MSTCLTD", year: 1998 },
  { label: "MTARTECH", year: 1998 },
  { label: "LODHA", year: 1998 },
  { label: "MGL", year: 1998 },
  { label: "MTNL", year: 1998 },
  { label: "MAHSCOOTER", year: 1998 },
  { label: "MAHSEAMLES", year: 1998 },
  { label: "M&MFIN", year: 1998 },
  { label: "M&M", year: 1998 },
  { label: "MAHINDCIE", year: 1998 },
  { label: "MHRIL", year: 1998 },
  { label: "MAHLIFE", year: 1998 },
  { label: "MAHLOG", year: 1998 },
  { label: "MAITHANALL", year: 1998 },
  { label: "MANINFRA", year: 1998 },
  { label: "MANAPPURAM", year: 1998 },
  { label: "MRPL", year: 1998 },
  { label: "MARICO", year: 1998 },
  { label: "MARKSANS", year: 1998 },
  { label: "MARUTI", year: 1998 },
  { label: "MASTEK", year: 1998 },
  { label: "MATRIMONY", year: 1998 },
  { label: "MFSL", year: 1998 },
  { label: "MAXHEALTH", year: 1998 },
  { label: "MAYURUNIQ", year: 1998 },
  { label: "MAZDOCK", year: 1998 },
  { label: "METROPOLIS", year: 1998 },
  { label: "MINDTREE", year: 1998 },
  { label: "MINDACORP", year: 1998 },
  { label: "MINDAIND", year: 1998 },
  { label: "MIDHANI", year: 1998 },
  { label: "MOLDTKPAC", year: 1998 },
  { label: "MOREPENLAB", year: 1998 },
  { label: "MOTILALOFS", year: 1998 },
  { label: "MPHASIS", year: 1998 },
  { label: "BECTORFOOD", year: 1998 },
  { label: "MCX", year: 1998 },
  { label: "MUTHOOTFIN", year: 1998 },
  { label: "NATCOPHARM", year: 1998 },
  { label: "NBCC", year: 1998 },
  { label: "NCC", year: 1998 },
  { label: "NEOGEN", year: 1998 },
  { label: "NESCO", year: 1998 },
  { label: "NHPC", year: 1998 },
  { label: "NIITLTD", year: 1998 },
  { label: "NLCINDIA", year: 1998 },
  { label: "NMDC", year: 1998 },
  { label: "NOCIL", year: 1998 },
  { label: "NRBBEARING", year: 1998 },
  { label: "NTPC", year: 1998 },
  { label: "NH", year: 1998 },
  { label: "NATIONALUM", year: 1998 },
  { label: "NFL", year: 1998 },
  { label: "NBVENTURES", year: 1998 },
  { label: "NAVINFLUOR", year: 1998 },
  { label: "NAVNETEDUL", year: 1998 },
  { label: "NAZARA", year: 1998 },
  { label: "NESTLEIND", year: 1998 },
  { label: "NETWORK18", year: 1998 },
  { label: "NEULANDLAB", year: 1998 },
  { label: "NEWGEN", year: 1998 },
  { label: "NILKAMAL", year: 1998 },
  { label: "NAM-INDIA", year: 1998 },
  { label: "NOVARTIND", year: 1998 },
  { label: "NUCLEUS", year: 1998 },
  { label: "OBEROIRLTY", year: 1998 },
  { label: "ONGC", year: 1998 },
  { label: "OIL", year: 1998 },
  { label: "OLECTRA", year: 1998 },
  { label: "OFSS", year: 1998 },
  { label: "ORIENTCEM", year: 1998 },
  { label: "ORIENTELEC", year: 1998 },
  { label: "OAL", year: 1998 },
  { label: "OCCL", year: 1998 },
  { label: "ORISSAMINE", year: 1998 },
  { label: "PCJEWELLER", year: 1998 },
  { label: "PCBL", year: 1998 },
  { label: "PIIND", year: 1998 },
  { label: "PNBGILTS", year: 1998 },
  { label: "PNBHOUSING", year: 1998 },
  { label: "PNCINFRA", year: 1998 },
  { label: "PSPPROJECT", year: 1998 },
  { label: "PFS", year: 1998 },
  { label: "PTC", year: 1998 },
  { label: "PVR", year: 1998 },
  { label: "PAGEIND", year: 1998 },
  { label: "PAISALO", year: 1998 },
  { label: "PANACEABIO", year: 1998 },
  { label: "PARAGMILK", year: 1998 },
  { label: "PERSISTENT", year: 1998 },
  { label: "PETRONET", year: 1998 },
  { label: "PFIZER", year: 1998 },
  { label: "PHOENIXLTD", year: 1998 },
  { label: "PIDILITIND", year: 1998 },
  { label: "PILANIINVS", year: 1998 },
  { label: "PEL", year: 1998 },
  { label: "POLYMED", year: 1998 },
  { label: "POLYCAB", year: 1998 },
  { label: "POLYPLEX", year: 1998 },
  { label: "POONAWALLA", year: 1998 },
  { label: "PFC", year: 1998 },
  { label: "POWERGRID", year: 1998 },
  { label: "PRAJIND", year: 1998 },
  { label: "PRAKASH", year: 1998 },
  { label: "DIAMONDYD", year: 1998 },
  { label: "PRESTIGE", year: 1998 },
  { label: "PRINCEPIPE", year: 1998 },
  { label: "PRSMJOHNSN", year: 1998 },
  { label: "PRIVISCL", year: 1998 },
  { label: "PGHL", year: 1998 },
  { label: "PGHH", year: 1998 },
  { label: "PSB", year: 1998 },
  { label: "PUNJABCHEM", year: 1998 },
  { label: "PNB", year: 1998 },
  { label: "PURVA", year: 1998 },
  { label: "QUESS", year: 1998 },
  { label: "QUICKHEAL", year: 1998 },
  { label: "RSYSTEMS", year: 1998 },
  { label: "RBLBANK", year: 1998 },
  { label: "RECLTD", year: 1998 },
  { label: "RHIM", year: 1998 },
  { label: "RITES", year: 1998 },
  { label: "RPSGVENT", year: 1998 },
  { label: "RADICO", year: 1998 },
  { label: "RVNL", year: 1998 },
  { label: "RAILTEL", year: 1998 },
  { label: "RAIN", year: 1998 },
  { label: "RAJESHEXPO", year: 1998 },
  { label: "RALLIS", year: 1998 },
  { label: "RAMCOIND", year: 1998 },
  { label: "RAMCOSYS", year: 1998 },
  { label: "RKFORGE", year: 1998 },
  { label: "RANEHOLDIN", year: 1998 },
  { label: "RCF", year: 1998 },
  { label: "RATNAMANI", year: 1998 },
  { label: "RTNINDIA", year: 1998 },
  { label: "RTNPOWER", year: 1998 },
  { label: "RAYMOND", year: 1998 },
  { label: "REDINGTON", year: 1998 },
  { label: "RELAXO", year: 1998 },
  { label: "RELIANCE", year: 1998 },
  { label: "RELINFRA", year: 1998 },
  { label: "RPOWER", year: 1998 },
  { label: "RELIGARE", year: 1998 },
  { label: "REPCOHOME", year: 1998 },
  { label: "ROSSARI", year: 1998 },
  { label: "ROUTE", year: 1998 },
  { label: "RUPA", year: 1998 },
  { label: "SHK", year: 1998 },
  { label: "SBICARD", year: 1998 },
  { label: "SBILIFE", year: 1998 },
  { label: "SEAMECLTD", year: 1998 },
  { label: "SIS", year: 1998 },
  { label: "SJVN", year: 1998 },
  { label: "SKFINDIA", year: 1998 },
  { label: "SMSPHARMA", year: 1998 },
  { label: "SRF", year: 1998 },
  { label: "SADBHAV", year: 1998 },
  { label: "SAGCEM", year: 1998 },
  { label: "SANDHAR", year: 1998 },
  { label: "SANGHIIND", year: 1998 },
  { label: "SANOFI", year: 1998 },
  { label: "SARDAEN", year: 1998 },
  { label: "SAREGAMA", year: 1998 },
  { label: "SASKEN", year: 1998 },
  { label: "SATIA", year: 1998 },
  { label: "SOTL", year: 1998 },
  { label: "SCHAEFFLER", year: 1998 },
  { label: "SCHNEIDER", year: 1998 },
  { label: "SEQUENT", year: 1998 },
  { label: "SESHAPAPER", year: 1998 },
  { label: "SHALBY", year: 1998 },
  { label: "SHANKARA", year: 1998 },
  { label: "SHANTIGEAR", year: 1998 },
  { label: "SHARDACROP", year: 1998 },
  { label: "SHARDAMOTR", year: 1998 },
  { label: "SFL", year: 1998 },
  { label: "SHILPAMED", year: 1998 },
  { label: "SCI", year: 1998 },
  { label: "SHOPERSTOP", year: 1998 },
  { label: "SHREECEM", year: 1998 },
  { label: "RENUKA", year: 1998 },
  { label: "SHRIRAMCIT", year: 1998 },
  { label: "SRTRANSFIN", year: 1998 },
  { label: "SIEMENS", year: 1998 },
  { label: "SIYSIL", year: 1998 },
  { label: "SNOWMAN", year: 1998 },
  { label: "SOBHA", year: 1998 },
  { label: "SOLARINDS", year: 1998 },
  { label: "SOLARA", year: 1998 },
  { label: "SOMANYCERA", year: 1998 },
  { label: "SHIL", year: 1998 },
  { label: "SONACOMS", year: 1998 },
  { label: "SONATSOFTW", year: 1998 },
  { label: "SOUTHBANK", year: 1998 },
  { label: "SPANDANA", year: 1998 },
  { label: "SPICEJET", year: 1998 },
  { label: "STARCEMENT", year: 1998 },
  { label: "SBIN", year: 1998 },
  { label: "SAIL", year: 1998 },
  { label: "SSWL", year: 1998 },
  { label: "SWSOLAR", year: 1998 },
  { label: "STLTECH", year: 1998 },
  { label: "STOVEKRAFT", year: 1998 },
  { label: "STAR", year: 1998 },
  { label: "SUBEXLTD", year: 1998 },
  { label: "SUBROS", year: 1998 },
  { label: "SUDARSCHEM", year: 1998 },
  { label: "SUMICHEM", year: 1998 },
  { label: "SPARC", year: 1998 },
  { label: "SUNPHARMA", year: 1998 },
  { label: "SUNTV", year: 1998 },
  { label: "SUNCLAYLTD", year: 1998 },
  { label: "SUNDARMFIN", year: 1998 },
  { label: "SUNDRMFAST", year: 1998 },
  { label: "SUNFLAG", year: 1998 },
  { label: "SUNTECK", year: 1998 },
  { label: "SUPRAJIT", year: 1998 },
  { label: "SUPREMEIND", year: 1998 },
  { label: "SUPPETRO", year: 1998 },
  { label: "SURYAROSNI", year: 1998 },
  { label: "SURYODAY", year: 1998 },
  { label: "SUVENPHAR", year: 1998 },
  { label: "SUZLON", year: 1998 },
  { label: "SWANENERGY", year: 1998 },
  { label: "SWARAJENG", year: 1998 },
  { label: "SYMPHONY", year: 1998 },
  { label: "SYNGENE", year: 1998 },
  { label: "TCIEXP", year: 1998 },
  { label: "TCNSBRANDS", year: 1998 },
  { label: "TTKPRESTIG", year: 1998 },
  { label: "TVTODAY", year: 1998 },
  { label: "TV18BRDCST", year: 1998 },
  { label: "TVSMOTOR", year: 1998 },
  { label: "TVSSRICHAK", year: 1998 },
  { label: "TAKE", year: 1998 },
  { label: "TNPL", year: 1998 },
  { label: "TANLA", year: 1998 },
  { label: "TASTYBITE", year: 1998 },
  { label: "TATACHEM", year: 1998 },
  { label: "TATACOFFEE", year: 1998 },
  { label: "TATACOMM", year: 1998 },
  { label: "TCS", year: 1998 },
  { label: "TATACONSUM", year: 1998 },
  { label: "TATAELXSI", year: 1998 },
  { label: "TATAINVEST", year: 1998 },
  { label: "TATAMETALI", year: 1998 },
  { label: "TATAMTRDVR", year: 1998 },
  { label: "TATAMOTORS", year: 1998 },
  { label: "TATAPOWER", year: 1998 },
  { label: "TATASTLLP", year: 1998 },
  { label: "TATASTEEL", year: 1998 },
  { label: "TTML", year: 1998 },
  { label: "TEAMLEASE", year: 1998 },
  { label: "TECHM", year: 1998 },
  { label: "TECHNOE", year: 1998 },
  { label: "TEJASNET", year: 1998 },
  { label: "NIACL", year: 1998 },
  { label: "RAMCOCEM", year: 1998 },
  { label: "THERMAX", year: 1998 },
  { label: "TIRUMALCHM", year: 1998 },
  { label: "THOMASCOOK", year: 1998 },
  { label: "THYROCARE", year: 1998 },
  { label: "TIDEWATER", year: 1998 },
  { label: "TIMETECHNO", year: 1998 },
  { label: "TIMKEN", year: 1998 },
  { label: "TINPLATE", year: 1998 },
  { label: "TITAN", year: 1998 },
  { label: "TORNTPHARM", year: 1998 },
  { label: "TORNTPOWER", year: 1998 },
  { label: "TCI", year: 1998 },
  { label: "TRENT", year: 1998 },
  { label: "TRIDENT", year: 1998 },
  { label: "TRIVENI", year: 1998 },
  { label: "TRITURBINE", year: 1998 },
  { label: "TIINDIA", year: 1998 },
  { label: "UCOBANK", year: 1998 },
  { label: "UFLEX", year: 1998 },
  { label: "UPL", year: 1998 },
  { label: "UTIAMC", year: 1998 },
  { label: "UJJIVAN", year: 1998 },
  { label: "UJJIVANSFB", year: 1998 },
  { label: "ULTRACEMCO", year: 1998 },
  { label: "UNICHEMLAB", year: 1998 },
  { label: "UNIONBANK", year: 1998 },
  { label: "UBL", year: 1998 },
  { label: "MCDOWELL-N", year: 1998 },
  { label: "USHAMART", year: 1998 },
  { label: "VGUARD", year: 1998 },
  { label: "VMART", year: 1998 },
  { label: "VIPIND", year: 1998 },
  { label: "VSTTILLERS", year: 1998 },
  { label: "VRLLOG", year: 1998 },
  { label: "VSTIND", year: 1998 },
  { label: "WABAG", year: 1998 },
  { label: "VAIBHAVGBL", year: 1998 },
  { label: "VAKRANGEE", year: 1998 },
  { label: "VALIANTORG", year: 1998 },
  { label: "VTL", year: 1998 },
  { label: "VARROC", year: 1998 },
  { label: "VBL", year: 1998 },
  { label: "VEDL", year: 1998 },
  { label: "VENKEYS", year: 1998 },
  { label: "VESUVIUS", year: 1998 },
  { label: "VINATIORGA", year: 1998 },
  { label: "VINDHYATEL", year: 1998 },
  { label: "IDEA", year: 1998 },
  { label: "VOLTAMP", year: 1998 },
  { label: "VOLTAS", year: 1998 },
  { label: "WABCOINDIA", year: 1998 },
  { label: "WELCORP", year: 1998 },
  { label: "WELENT", year: 1998 },
  { label: "WELSPUNIND", year: 1998 },
  { label: "WSTCSTPAPR", year: 1998 },
  { label: "WESTLIFE", year: 1998 },
  { label: "WHEELS", year: 1998 },
  { label: "WHIRLPOOL", year: 1998 },
  { label: "WIPRO", year: 1998 },
  { label: "WOCKPHARMA", year: 1998 },
  { label: "WONDERLA", year: 1998 },
  { label: "YESBANK", year: 1998 },
  { label: "ZEEL", year: 1998 },
  { label: "ZENSARTECH", year: 1998 },
  { label: "ZYDUSWELL", year: 1998 },
  { label: "ECLERX", year: 1998 },
  { label: "DOLLAR", year: 1998 },
  { label: "LALPATHLAB", year: 1998 },
  { label: "DRREDDY", year: 1998 },
  { label: "DREDGECORP", year: 1998 },
  { label: "EIDPARRY", year: 1998 },
  { label: "EIHOTEL", year: 1998 }
  
  
  
  
  
    
    ];
    
    return (
        <Image>
            <Heading>Stockimia</Heading>
            {/* <SubHeading>Search Stock</SubHeading> */}
            <div>
            <div >
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    className="home-textinput input"
                    options={top100Films}
                    // filterOptions={filterOptions}
                    // getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    onChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Search Stock" />
                    )}
                    />
                </div>
                <Link to={`/stock/?stock=${stock}`}>
                    <div className="home-subscribes" >
                    <span className="home-text114">   Filter Stock</span>
                    </div>
                </Link>  
                <Link to={`/AI`}>
                    <div className="home-subscribe" >
                    <span className="home-text116">   USE PRO APP </span>
                    </div>
                </Link>  
            </div>
            
        </Image>
    );
};

export default Banner;
