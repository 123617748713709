
    import { useState } from 'react';

    import { BrowserRouter, Routes, Route , Navigate, Outlet} from 'react-router-dom';

    import DataProvider from './context/DataProvider';
    //components
    import Header from './components/header/Header';
    import Home from './components/home/Home';
    import CreatePost from './components/create/CreatePost';
    import DetailView from './components/details/DetailView';
    import Update from './components/create/Update';
    import About from './components/about/About';
    import Contact from './components/contact/Contact';
    import Login from './components/account/Login';
    import SearchStock from './components/aistock/SearchStock';
    import Ai from './components/aiFive/Ai';
    import AiService from './components/aiFive/AiService';
    import Aboutstock from './components/aboutStock/Aboutstock';
    import CompanyHome from './components/comanyhome/views/CompanyHome';
    import Price from './components/paymentqr/Paymentqr';
    import Qrcode from './components/paymentqr/qrcodedetail';
    
    
    import AiSignal from './components/aithink/AiSignal';
    import AboutSignal from './components/aithink/AboutSignal';
    import AiWeek from './components/aiweek/AiWeek';
    import AboutWeek from './components/aiweek/AboutWeek';
    import Livesearchfilter from './components/livefilter/Livesearchfilter';
    import AiPortfolio from './components/aiportfolio/Aiportfolio';
    import Aiworld from './components/aiworld/Aiworld';
    import AboutWorld from './components/aboutworld/AboutWorld';
    import PortfolioSection from './components/portfolioSection/PortfolioSection';
    import Aifriend from './components/aifriend/Aifriend';
    import Airesource from './components/airesource/Airesource';
    import AiresourceMF from './components/airesource/AiresourceMF';
    import Watch from './components/freeservice/Watch';
    import Video from './components/freeservice/video/Video';
    import Personalfinance from './components/freeservice/personalfinance/Personalfinance';
    import Mutualfunds from './components/freeservice/mutualfunds/Mutualfunds';
    import Market from './components/freeservice/market/Market';
    import Forum from './components/freeservice/forum/Forum';
    import Commodities from './components/freeservice/commoditities/Commodities';
    import News from './components/freeservice/news/News';
    import Part from './components/freeservice/part/Part';
    import StockAbout from './components/stockabout/StockAbout';
    



    const PrivateRoute = ({ isAuthenticated, ...props }) => {
    const token = sessionStorage.getItem('accessToken');
    return isAuthenticated && token ? 
        <>
        <Header />
        <Outlet /> 
        </> : <Navigate replace to='/login' />
    };




    function App() {

    const [isAuthenticated, isUserAuthenticated] = useState(false);

    return (
        
        <DataProvider>
            <BrowserRouter>
            
            <div style={{ marginTop: 64 }}>
            <Routes>
            <Route path='/login' element={<Login isUserAuthenticated={isUserAuthenticated}/>}/>
            <Route path='/home/company' element={<CompanyHome isUserAuthenticated={isUserAuthenticated}/>}/>
            <Route path='/prices' element={<Price isUserAuthenticated={isUserAuthenticated}/>}/>
            <Route path='/prices/plane/:prices' element={<Qrcode isUserAuthenticated={isUserAuthenticated}/>}/>
            
            {/* <Route path='/' element={<Home/>}/> */}
            <Route path='/' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/' element={<Home />} />
            </Route>

            
            <Route path='/LiveFilter' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/LiveFilter' element={<Livesearchfilter/>} />
            </Route>

            <Route path='/AI' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI' element={<Ai />} />
            </Route>

            <Route path='/AI/:stockMarket' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/:stockMarket' element={<AiService />} />
            </Route>

            <Route path='AI/Indian-Stock-Market/AI-Vision' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Vision' element={<SearchStock />} />
            </Route>

            <Route path='/stock' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/stock' element={<StockAbout />} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Think' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Think' element={<AiSignal/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Week' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Week' element={<AiWeek/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Portfolio' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Portfolio' element={<AiPortfolio/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Portfolio/:portfoliochange' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Portfolio/:portfoliochange' element={<PortfolioSection/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-World' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-World' element={<Aiworld/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Friend' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Friend' element={<Aifriend/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-World/:portfoliochange' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-World/:portfoliochange' element={<AboutWorld/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Resource' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Resource' element={<Airesource/>} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Resource/MF' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Resource/MF' element={<AiresourceMF/>} />
            </Route>

            <Route path='/watch' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch' element={<Watch/>} />
            </Route>

            <Route path='/news' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/news' element={<News/>} />
            </Route>

            <Route path='/watch/Video' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Video' element={<Video/>} />
            </Route>


            <Route path='/watch/Personal-Finance' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Personal-Finance' element={<Personalfinance/>} />
            </Route>

            <Route path='/watch/Mutual-Funds' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Mutual-Funds' element={<Mutualfunds/>} />
            </Route>

            <Route path='/watch/Market' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Market' element={<Market/>} />
            </Route>

            <Route path='/watch/Forum' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Forum' element={<Forum/>} />
            </Route>

            <Route path='/watch/Commodities' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/watch/Commodities' element={<Commodities/>} />
            </Route>

            <Route path='/freewatch' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/freewatch' element={<Part/>} />
            </Route>

            <Route path='/create' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/create' element={<CreatePost />} />
            </Route>

            <Route path='/details/:id' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/details/:id' element={<DetailView />} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Vision/:stockName' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Vision/:stockName' element={<Aboutstock />} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Think/:signal' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Think/:signal' element={<AboutSignal />} />
            </Route>

            <Route path='/AI/Indian-Stock-Market/AI-Week/:weekSignal' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/AI/Indian-Stock-Market/AI-Week/:weekSignal' element={<AboutWeek />} />
            </Route>

            <Route path='/update/:id' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/update/:id' element={<Update />} />
            </Route>

            <Route path='/about' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/about' element={<About />} />
            </Route>

            <Route path='/contact' element={<PrivateRoute isAuthenticated={isAuthenticated} />} >
                <Route path='/contact' element={<Contact />} />
            </Route>

            </Routes>
            </div>
            </BrowserRouter>
        </DataProvider>
        
    );
    }

    export default App;
