
    // import * as React from 'react';
    // import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
    // // Importing useMovieData is not necessary if you're not using it anymore
    // // import { useMovieData } from '@mui/x-data-grid-generator';
    
    // const AiPortfolio = () => {
    //     // Assuming your data object looks something like this
    //     const rows = [
    //         { id: 1, company: 'Company A', revenue: 1000 },
    //         { id: 2, company: 'Company B', revenue: 1500 },
    //         { id: 3, company: 'Company A', revenue: 100 },
    //         // Add more rows as needed
    //     ];
    
    //     // Define your columns
    //     const columns = [
    //         { field: 'id', headerName: 'ID', width: 100 },
    //         { field: 'company', headerName: 'Company', width: 200 },
    //         { field: 'revenue', headerName: 'Revenue', width: 150 },
    //         // Add more columns as needed
    //     ];
    
    //     const apiRef = useGridApiRef();
    
    //     const initialState = useKeepGroupedColumnsHidden({
    //         apiRef,
    //         initialState: {
    //             rowGrouping: {
    //                 model: ['company'],
    //             },
    //         },
    //     });
    
    //     const onRowClick = React.useCallback(
    //         (params) => {
    //             const rowNode = apiRef.current.getRowNode(params.id);
    //             if (rowNode && rowNode.type === 'group') {
    //                 apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
    //             }
    //         },
    //         [apiRef],
    //     );
    
    //     return (
    //         <div style={{ height: 400, width: '100%' }}>
    //             <DataGridPremium
    //                 rows={rows} // Pass your data to the rows prop
    //                 columns={columns} // Pass your columns definition to the columns prop
    //                 apiRef={apiRef}
    //                 initialState={initialState}
    //                 onRowClick={onRowClick}
    //             />
    //         </div>
    //     );
    // }
    
    // export default AiPortfolio;
    // Import React libraries
    // Import React libraries
    // Import React libraries
    // Import React libraries
    // Import React libraries
    // Import React libraries
    // Import React libraries




    import React, { useState, useCallback, useEffect} from 'react';
    import { useSpring, animated } from "react-spring";
    import Banner from '../../banner/Banner';
    import { Autocomplete, TextField } from "@mui/material";
    import { styled,Button } from '@mui/material';
    import { useNavigate,Link} from 'react-router-dom'; // Import useNavigate

    import './News.css'; // Import CSS file

//     const EditButton = styled(Button)`
//     display: flex;
//     align-items: center;
//     border-radius: 15px;
//     background-color: none; 
// `;

const EditButton = styled(Button)`
display: flex;
align-items: center;
border-radius: 15px;
background-color: none; /* This line is causing the issue */
color: inherit; /* Ensuring the text color is inherited */
border: 1px solid transparent; /* Adding a border to maintain button appearance */
&:hover {
    border: 1px solid #000; /* Changing border color on hover */
}
`;


    // Define the DataGridPremium component
    function DataGridPremium({ rows, columns }) {
        const [selectedCompany, setSelectedCompany] = useState(null);
        const [portfoliosize, setportfoliosize] = useState(null);
        // Function to handle row click event
        const onRowClick = useCallback(
            (company, revenue) => {
                setSelectedCompany(selectedCompany === company ? null : company);
                
            },
            [selectedCompany]
        );
        


    
        // Extract unique company names
        const uniqueCompanies = [...new Set(rows.map(row => row.company))];
        
        const navigate = useNavigate(); // Get the navigate function from useNavigate hook
        // Function to render the DataGridPremium component
        return (
            
            <div className="data-grid-container">
                <table className="data-grid-table">
                    <tbody>
                        {uniqueCompanies.map(company => (
                            <React.Fragment key={company}>
                                <tr className="data-grid-row" onClick={() => onRowClick(company,rows.find(row => row.company === company).revenue)}>
                                    <td>{company}</td>
                                </tr>
                                {selectedCompany === company && rows.map(row => (
                                    row.company === company && (
                                        <tr key={row.id} className="data-grid-details-row">
                                            <>
                                            <td>{`${row.revenue}`}</td>
                                            
                                            {/* Pass the company to the onClick handler */}
                                            <td className="home-subscribe"><Link to={`/freewatch/?part=${row.revenue}`}><EditButton >VIEW</EditButton> </Link></td>
                                        
                                            </>
                                        </tr>
                                    )
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    
    // Define your AiPortfolio component
    const News = () => {
        // const [circles, setCircles] = useState([]);
    
        // useEffect(() => {
        //     // Initialize circles
        //     const initialCircles = Array.from({ length: 7 }, (_, index) => ({
        //     id: index,
        //     size: Math.random() * 300 + 150, // Random size between 50 and 100
        //     color: getRandomColor(), // Random color
        //     position: {
        //         x: Math.random() * window.innerWidth,
        //         y: Math.random() * window.innerHeight,
        //     },
        //     }));
        //     setCircles(initialCircles);
        
        //     // Set interval to update circles every 10 seconds
        //     const intervalId = setInterval(updateCircles, 100000);
        //     return () => clearInterval(intervalId);
        // }, []);
    
        // const updateCircles = () => {
        //     setCircles((prevCircles) => {
        //     return prevCircles.map((circle) => ({
        //         ...circle,
        //         position: {
        //         x: Math.random() * window.innerWidth,
        //         y: Math.random() * window.innerHeight,
        //         },
        //     }));
        //     });
        // };
        
        // const getRandomColor = () => {
        //     const letters = "0123456789ABCDEF";
        //     let color = "#";
        //     for (let i = 0; i < 6; i++) {
        //     color += letters[Math.floor(Math.random() * 16)];
        //     }
        //     return color;
        // };
    
        const [circles, setCircles] = useState([]);
    
        useEffect(() => {
            // Initialize circles
            const initialCircles = Array.from({ length: 150 }, (_, index) => ({
                id: index,
                size: Math.random() * (.5+.02)*25, // Random size between 150 and 450
                color: getRandomColor(), // Random color
                angle: Math.random() * Math.PI * 2, // Random initial angle
                speed: Math.random() * 0.005 + 0.001, // Random speed between 0.005 and 0.015
            }));
            setCircles(initialCircles);
        
            // Set interval to update circles every 10 milliseconds
            const intervalId = setInterval(updateCircles, 10);
            return () => clearInterval(intervalId);
        }, []);
        
        const updateCircles = () => {
            setCircles(prevCircles => 
                prevCircles.map(circle => {
                    const newAngle = circle.angle + circle.speed;
                    const centerX = window.innerWidth / 2;
                    const centerY = window.innerHeight / 2;
                    const radius = Math.min(centerX, centerY) * 0.8;
        
                    const newX = centerX + radius * Math.cos(newAngle);
                    const newY = centerY + radius * Math.sin(newAngle);
        
                    const newPosition = {
                        x: newX,
                        y: newY
                    };
        
                    return {
                        ...circle,
                        angle: newAngle,
                        position: newPosition
                    };
                })
            );
        };
        
        
        const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        };
    
    // Function to get a random direction
    
    
    
    
        const slideIn = useSpring({
            from: { transform: "translateY(100px)" },
            to: { transform: "translateY(0)" },
            config: { duration: 500 },
        });
        // Sample data
        const rows = [
            
            { id: 1, company: 'latest-news', revenue: 'latest-news'},
            { id: 2, company: 'economy', revenue: 'economy'},
            { id: 3, company: 'Companies', revenue: 'Companies'},
            { id: 4, company: 'Mutual-Funds', revenue: 'Mutual-Funds' },
            { id: 5, company: 'Personal-Finance', revenue: 'Personal-finance' },
            { id: 6, company: 'IPO', revenue: 'IPO' },
            { id: 7, company: 'Startups', revenue: 'Startups' },
            { id: 8, company: 'Real-Estate', revenue: 'Real-Estate' },
            { id: 9, company: 'SME', revenue: 'SME'},
            { id: 10, company: 'National-&-World', revenue: 'World' },
            { id: 11, company: 'National-&-World', revenue: 'Politics' },
            { id: 12, company: 'National-&-World', revenue: 'India' },
            { id: 13, company: 'Markets', revenue: 'Stock' },
            { id: 14, company: 'Markets', revenue: 'Technical-Analysis' },
            { id: 15, company: 'Markets', revenue: "Equity-Research" },
            { id: 16, company: 'Markets', revenue: 'Commodity' },
            { id: 17, company: 'Markets', revenue: 'Currency' },
            { id: 18, company: 'Markets', revenue: 'Gold-Rate' },
            { id: 19, company: 'Markets', revenue: 'Silver-Rate' },
            { id: 20, company: 'Markets', revenue: 'AQI' },
            { id: 21, company: 'Trends', revenue: 'Trends' },
            { id: 22, company: 'Opinion', revenue: 'Opinion'},
            { id: 23, company: 'Technology', revenue: 'Technology'},
            { id: 24, company: 'Podcast', revenue: 'Podcast'},
            { id: 25, company: 'Photos', revenue: 'Photos' },
            { id: 26, company: 'Infography', revenue: 'Infography' },
            { id: 27, company: 'Video', revenue: 'Video' },
            { id: 28, company: 'WebStories', revenue: 'WebStories' },
            { id: 29, company: 'Crypto-Currency', revenue: 'Crypto-Currency' },
            { id: 30, company: 'Other', revenue: 'Entertainment' },
            { id: 31, company: 'Other', revenue: 'Sports' },
            { id: 32, company: 'Other', revenue: 'Lifestyle' },
            { id: 33, company: 'Other', revenue: 'Health-and-Fitness' },
            { id: 34, company: 'Other', revenue: 'Education' },
            { id: 35, company: 'Other', revenue: 'Jobs' },
            { id: 36, company: 'Other', revenue: "Science" },
            { id: 37, company: 'Other', revenue: 'Astro' },
            { id: 38, company: 'Other', revenue: "Travel" },
            { id: 39, company: 'Technology', revenue: "Auto" },
            { id: 40, company: 'Technology', revenue: "Fintech" },
            { id: 41, company: 'Technology', revenue: "Explainers" },
            
            
            // Add more rows as needed
        ];
        // Define columns
        const columns = [
            { field: 'company', headerName: 'Company' },
        ];
    
        // Render the DataGridPremium component
        return (
            <div className="stock-market-background">
        {circles.map((circle) => (
            <div
            key={circle.id}
            className="circle"
            style={{
                width: `${circle.size}px`,
                height: `${circle.size}px`,
                backgroundColor: circle.color,
                left: `${circle.position ? circle.position.x : 0}px`,
            top: `${circle.position ? circle.position.y : 0}px`,
            }}
            />
        ))}
    
        <div className="stock-market-background">
            <div>
            <div>
            <Banner/>
            </div>
                <animated.div style={slideIn}>
            <DataGridPremium rows={rows} columns={columns} />
            </animated.div>
            </div>
            </div>
            </div>
        );
    };
    
    // Export the AiPortfolio component
    
    export default News;
    
    