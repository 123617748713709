
    import React, { useState, useEffect } from "react";
    import { useSpring, animated } from "react-spring";
    import { Autocomplete, TextField } from "@mui/material";
    import { Link } from "react-router-dom";
    import "./Ai.css"; // Import CSS file for custom styling

    const Ai = () => {
    const [stockMarket, setStockMarket] = useState("");
    const [circles, setCircles] = useState([]);
    

    useEffect(() => {
        // Initialize circles
        const initialCircles = Array.from({ length: 25 }, (_, index) => ({
        id: index,
        size: Math.random() * 50 + 150, // Random size between 50 and 100
        color: getRandomColor(), // Random color
        position: {
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
        },
        }));
        setCircles(initialCircles);

        // Set interval to update circles every 10 seconds
        const intervalId = setInterval(updateCircles, 100000);
        return () => clearInterval(intervalId);
    }, []);

    const updateCircles = () => {
        setCircles((prevCircles) => {
        return prevCircles.map((circle) => ({
            ...circle,
            position: {
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            },
        }));
        });
    };

    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const slideIn = useSpring({
        from: { transform: "translateY(100px)" },
        to: { transform: "translateY(0)" },
        config: { duration: 500 },
    });

    const handleInputChange = (event, value) => {
        // setStockName(event.target.value);
        setStockMarket(value ? value.label : ""); // Assuming value is an object with a 'label' property
    };

    

    const top100Films = [
        // { label: "Crypto-Curency", year: 1994 },
        { label: "Indian-Stock-Market", year: 1972 },
        
    ];

    // const filterOptions = (options, { inputValue }) => {
    //   return options.filter(option => {
    //     // Check if the year matches the input value
    //     return option.year.toString().includes(inputValue);
    //   });
    // };

    return (
        <div className="stock-market-background">
        {circles.map((circle) => (
            <div
            key={circle.id}
            className="circle"
            style={{
                width: `${circle.size}px`,
                height: `${circle.size}px`,
                backgroundColor: circle.color,
                left: `${circle.position.x}px`,
                top: `${circle.position.y}px`,
            }}
            />
        ))}

        <div className="stock-market-background">
            <animated.div style={slideIn}>
            
            <div className="home-left4">
                <span className="home-text115">Subscribe to our newsletter</span>
                
                <div>
                <div>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    className="home-textinput input"
                    options={top100Films}
                    // filterOptions={filterOptions}
                    // getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    onChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Stock Market" />
                    )}
                    />
                </div>
                <Link to={`${stockMarket}`}>
                    <div className="home-subscribe" >
                    <span className="home-text116">Select Market</span>
                    </div>
                </Link>
                </div>
                <span className="home-text117">
                By subscribing to our newsletter you agree with our Terms and
                Conditions.
                </span>
            </div>
            </animated.div>
        </div>
        </div>
    );
    };

    export default Ai;
