import { useEffect, useState } from 'react';

import { Grid, Box } from '@mui/material';
import { Link,  useSearchParams ,useNavigate} from 'react-router-dom';

// import { getAllPosts } from '../../../service/api';
import { API } from '../../../service/api';

//components
import Post from './Post';



const Posts = () => {
    const [posts, getPosts] = useState([]);
    
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const market = searchParams.get('market');
    const navigate = useNavigate();
    
useEffect(() => {
    const fetchData = async () => { 
        try {
            // Check if category is not null or undefined before making the API call
            let response;
            if (category && market) {
                response = await API.getAllPosts({ category: category, market: market });
            } else if (category) {
                response = await API.getAllPosts({ category: category });
            } else if (market) {
                response = await API.getAllPosts({ market: market });
            }else {
                response = await API.getAllPosts();
            }

            if (response.isSuccess) {
                // getPosts(response.data);
                const reversedPosts = response.data.reverse();
                getPosts(reversedPosts);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
            
            if (error.response && error.response.status === 403) {
            // If error status is 403, navigate to the /login route
            navigate("/login");
            }
        }
    }
    fetchData();
}, [category,navigate,market]);

    return (
        <>
            {
                posts?.length ? posts.map(post => (
                    <Grid item lg={3} sm={4} xs={12}>
                        <Link style={{textDecoration: 'none', color: 'inherit'}} to={`details/${post._id}`}>
                            <Post post={post} />
                        </Link>
                    </Grid>
                )) : <Box style={{color: '878787', margin: '30px 80px', fontSize: 18}}>
                        No data is available for selected category
                    </Box>
            }
        </>
    )
}

export default Posts;