    // import { Button, Table, TableHead, TableRow, TableCell, TableBody, styled } from '@mui/material';
    // import { Link, useSearchParams } from 'react-router-dom';

    // import { categories } from '../../constants/data';

    // const StyledTable = styled(Table)`
    //     border: 1px solid rgba(224, 224, 224, 1);
    // `;

    // const StyledButton = styled(Button)`
    //     margin: 20px;
    //     width: 85%;
    //     background: #6495ED;
    //     color: #fff;
    //     text-decoration: none;
    // `;

    // const StyledLink = styled(Link)`
    //     text-decoration: none;
    //     color: inherit;
    // `;

    // const Categories = () => {
    //     const [searchParams] = useSearchParams();
    //     const category = searchParams.get('category');
    //     const market = searchParams.get('market');

    //     return (
    //         <>
    //         {/* to={`/create?category=${category || ''}`} */}
    //             <Link  to={`/create?category=${category || ''}&market=${market || ''}`} style={{ textDecoration: 'none' }}>
    //                 <StyledButton variant="contained">Create Blog</StyledButton>
    //             </Link>

    //             {/* <StyledButton variant="contained">Create Blog</StyledButton> */}
    //             <StyledTable>
    //                 <TableHead>
    //                     <TableRow>
    //                         <TableCell>
    //                             <StyledLink to={"/"}>
    //                                 All Categories
    //                             </StyledLink>
    //                         </TableCell>
    //                     </TableRow>
    //                 </TableHead>
    //                 <TableBody>
    //                     {
    //                         categories.map(category => (
    //                             <TableRow key={category.id}>
    //                                 <TableCell>
    //                                 {/* to={`/?category=${category.type}`} */}
    //                                     <StyledLink to={`/?category=${category.type}&market=${category.country}`}>
    //                                         {category.type}
    //                                     </StyledLink>
    //                                 </TableCell>
    //                             </TableRow>
    //                         ))
    //                     }
    //                 </TableBody>
    //             </StyledTable>
    //         </>
    //     )
    // }

    // export default Categories;

    import React, { useState } from "react";
    import { Link, useSearchParams, useNavigate } from "react-router-dom";
    import {
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    styled,
    } from "@mui/material"; // Import your styled components

    const StyledTable = styled(Table)`
    border: 1px solid rgba(224, 224, 224, 1);
    `;

    const StyledButton = styled(Button)`
    margin: 20px;
    width: 85%;
    background: #6495ed;
    color: #fff;
    text-decoration: none;
    `;

    const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    `;
    const Categories = () => {
    const [selectedCountry, setSelectedCountry] = useState(""); // State to track selected country
    const [searchParams] = useSearchParams();
    const category = searchParams.get("category");
    const navigate = useNavigate();
    // Dummy data for categories
    const categoriest = [
        { id: 1, type: "Large-cap", country: "India" },
        { id: 2, type: "Mid-cap", country: "India" },
        { id: 3, type: "Small-cap", country: "India" },
        { id: 4, type: "Start-up", country: "India" },
        { id: 5, type: "Tranding", country: "India" },
        { id: 6, type: "Large-cap", country: "USA" },
        { id: 7, type: "Mid-cap", country: "USA" },
        { id: 8, type: "Small-cap", country: "USA" },
        { id: 9, type: "Start-up", country: "USA" },
        { id: 10, type: "Tranding", country: "USA" },
    ];

    return (
        <>
        <div style={{ justifyContent: "space-between" }}>
            <div>
            <Link
                to={`/create?category=${category}&market=${selectedCountry}`}
                style={{ textDecoration: "none" }}
            >
                <StyledButton variant="contained">Create Blog</StyledButton>
            </Link>
            </div>
        </div>

        <StyledTable>
            <TableHead>
            <TableRow style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <TableCell>
                <StyledLink to={"/"} onClick={() => setSelectedCountry("")}>All Categories</StyledLink>
                </TableCell>
                <div style={{ marginRight: "10px" }}>

                {selectedCountry !== "India" && (
                <StyledButton
                    variant="contained"
                    onClick={() => {
                        setSelectedCountry("India");
                        navigate(`/?market=India`);
                    }}

                >
                    India
                </StyledButton>
                )}
                {selectedCountry !== "USA" && (
                <StyledButton
                    variant="contained"
                    onClick={() => {
                        setSelectedCountry("USA");
                        navigate(`/?market=USA`);
                    }}
                >
                    USA
                </StyledButton>
                )}
                </div>
            </TableRow>
            </TableHead>
            <TableBody>
            {categoriest
                .filter((category) => category.country === selectedCountry) // Filter categories based on selected country
                .map((category) => (
                <TableRow key={category.id}>
                    <TableCell>
                    <StyledLink
                        to={`/?category=${category.type}&market=${category.country}`}
                    >
                        {category.type}
                    </StyledLink>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
        </StyledTable>
        </>
    );
    };

    export default Categories;
