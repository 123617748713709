import React from 'react';
import { useLocation } from 'react-router-dom';

function Part() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const part = params.get('part');

    let iframeUrl = '';

    // Assuming row.revenue is a variable you're expecting from the URL parameter
    switch (part) {
        case 'latest-news':
            iframeUrl = 'https://www.moneycontrol.com/budget/';
            break;
        case 'economy':
            iframeUrl = 'https://www.moneycontrol.com/news/business/economy/';
            break;
        case 'Companies':
            iframeUrl = 'https://www.moneycontrol.com/news/tags/companies.html';
            break;
        case 'Mutual-Funds':
            iframeUrl = 'https://www.moneycontrol.com/news/business/mutual-funds/';
            break;
        case 'Personal-finance':
            iframeUrl = 'https://www.moneycontrol.com/news/business/personal-finance/';
            break;
        case 'IPO':
            iframeUrl = 'https://www.moneycontrol.com/news/business/ipo/';
            break;
        case 'Startups':
            iframeUrl = 'https://www.moneycontrol.com/mc-buzz/';
            break;
        case 'Real-Estate':
            iframeUrl = 'https://www.moneycontrol.com/real-estate-property/';
            break;
        case 'SME':
            iframeUrl = 'https://www.moneycontrol.com/news/sme';
            break;
        case 'Commodities':
            iframeUrl = 'https://www.moneycontrol.com/commodity/';
            break;
            case 'World':
                iframeUrl = 'https://www.moneycontrol.com/news/world/';
                break;
            case 'Politics':
                iframeUrl = 'https://www.moneycontrol.com/news/politics/';
                break;
            case 'India':
                iframeUrl = 'https://www.moneycontrol.com/news/india/';
                break;
            case 'Stock':
                iframeUrl = 'https://www.moneycontrol.com/news/business/stocks/';
                break;
            case 'Technical-Analysis':
                iframeUrl = 'https://www.moneycontrol.com/equity-research/';
                break;
            case 'Equity-Research':
                iframeUrl = 'https://www.moneycontrol.com/equity-research/';
                break;
            case 'Commodity':
                iframeUrl = 'https://www.moneycontrol.com/news/';
                break;
            case 'Currency':
                iframeUrl = 'https://www.moneycontrol.com/news/tags/currency.html';
                break;
            case 'Gold-Rate':
                iframeUrl = 'https://www.moneycontrol.com/news/gold-rates-today/';
                break;
            case 'Silver-Rate':
                iframeUrl = 'https://www.moneycontrol.com/news/silver-rates-today/';
                break;
            case 'AQI':
                iframeUrl = 'https://www.moneycontrol.com/aqi/';
                break;
                case 'Trends':
                    iframeUrl = 'https://www.moneycontrol.com/news/trends/';
                    break;
                case 'Opinion':
                    iframeUrl = 'https://www.moneycontrol.com/news/opinion/';
                    break;
                case 'Explainers':
                        iframeUrl = 'https://www.moneycontrol.com/explainers/';
                        break;
                case 'Fintech':
                    iframeUrl = 'https://www.moneycontrol.com/news/fintech/';
                    break;    
                case 'Technology':
                    iframeUrl = 'https://www.moneycontrol.com/news/technology/';
                    break;
                    case 'Auto':
                        iframeUrl = 'https://www.moneycontrol.com/news/technology/auto';
                        break;    
                case 'Podcast':
                    iframeUrl = 'https://www.moneycontrol.com/podcast/';
                    break;
                case 'Photos':
                    iframeUrl = 'https://www.moneycontrol.com/news/photos/';
                    break;
                case 'Infography':
                    iframeUrl = 'https://www.moneycontrol.com/news/infographic/';
                    break;
                case 'Video':
                    iframeUrl = 'https://www.moneycontrol.com/news/videos/';
                    break;
                case 'WebStories':
                    iframeUrl = 'https://www.moneycontrol.com/web-stories/';
                    break;
                case 'Crypto-Currency':
                    iframeUrl = 'https://www.moneycontrol.com/news/tags/cryptocurrency.html';
                    break;
                    case 'Entertainment':
                        iframeUrl = 'https://www.moneycontrol.com/entertainment/';
                        break;
                    case 'Sports':
                        iframeUrl = 'https://www.moneycontrol.com/sports/';
                        break;
                    case 'Lifestyle':
                        iframeUrl = 'https://www.moneycontrol.com/lifestyle/';
                        break;
                    case 'Health-and-Fitness':
                        iframeUrl = 'https://www.moneycontrol.com/health-and-fitness/';
                        break;
                    case 'Education':
                        iframeUrl = 'https://www.moneycontrol.com/education/';
                        break;
                    case 'Jobs':
                        iframeUrl = 'https://www.moneycontrol.com/jobs/';
                        break;
                    case 'Science':
                        iframeUrl = 'https://www.moneycontrol.com/news/tags/science.html';
                        break;
                    case 'Astro':
                        iframeUrl = 'https://www.moneycontrol.com/astrology/';
                        break;
                    case 'Travel':
                        iframeUrl = 'https://www.moneycontrol.com/news/tags/travel.html';
                        break;
                        
            case 'Global-markets':
                iframeUrl = 'https://www.moneycontrol.com/markets/global-indices/';
                break;
            // https://www.moneycontrol.com/markets/global-indices/
        case 'US-Markets':
            iframeUrl = 'https://www.moneycontrol.com/us-markets';
            break;
        case 'Big-Shark-Portfolios':
            iframeUrl = 'https://www.moneycontrol.com/india-investors-portfolio/';
            break;
        case 'Indian-Indices':
            iframeUrl = 'https://www.moneycontrol.com/markets/indian-indices/';
            break;
        case 'Currencies':
            iframeUrl = 'https://www.moneycontrol.com/markets/currencies/';
            break;
        case 'CryptoCurrency':
            iframeUrl = 'https://www.moneycontrol.com/crypto-market/market-movers/top-gainers/inr';
            break;
        case 'Bonds':
            iframeUrl = 'https://www.moneycontrol.com/bonds/';
            break;
        case 'Pre-Market':
            iframeUrl = 'https://www.moneycontrol.com/markets/premarket/';
            break;
        case 'Advice':
            iframeUrl = 'https://www.moneycontrol.com/markets/stock-advice/';
            break;
        case 'Broker-Research':
            iframeUrl = 'https://www.moneycontrol.com/broker-research/';
            break;
        case 'Technicals':
            iframeUrl = 'https://www.moneycontrol.com/markets/technicals/';
            break;
        case 'All-Stats':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/index.php';
            break;
        case 'Top-Gainers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nsegainer/index.php';
            break;
        case 'Top-Losers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nseloser/index.php';
            break;
        case 'Only-Buyers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/onlybuyers.php';
            break;
        case 'Only-Sellers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/onlysellers.php';
            break;
        case '52-Week-High':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nsehigh/index.php';
            break;
        case '52-Week-Low':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nselow/index.php';
            break;
        case 'Price-Shockers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nse_pshockers/index.php';
            break;
        case 'Volume-Shockers':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nse_vshockers/index.php';
            break;
        case 'Most-Active-Stocks':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/nsemact1/index.php';
            break;
        case 'Stock-Scanner':
            iframeUrl = 'https://www.moneycontrol.com/stock-scanner/';
            break;
        case 'Economic-Indicators':
            iframeUrl = 'https://www.moneycontrol.com/economic-indicators/';
            break;
        case 'Economy-Calendar':
            iframeUrl = 'https://www.moneycontrol.com/economic-calendar';
            break;
        case 'Dashbord':
            iframeUrl = 'https://www.moneycontrol.com/stocksmarketsindia/?dashboard=l2';
            break;
        case 'F&O':
            iframeUrl = 'https://www.moneycontrol.com/markets/fno-market-snapshot';
            break;
        case 'FII-&-DII-Activity':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketstats/fii_dii_activity/index.php';
            break;
        case 'Corporate-Action':
            iframeUrl = 'https://www.moneycontrol.com/stocks/marketinfo/meetings.php?opttopic=brdmeeting';
            break;
        case 'Company-Earnings':
            iframeUrl = 'https://www.moneycontrol.com/markets/earnings/';
            break;
        case 'Commoditys':
            iframeUrl = 'https://www.moneycontrol.com/commodity/';
            break;
        case 'Technical-Trends':
            iframeUrl = 'https://www.moneycontrol.com/markets/technical-trends/';
            break;
        case 'IPOs':
            iframeUrl = 'https://www.moneycontrol.com/ipo/';
            break;
        case 'Webinar':
            iframeUrl = 'https://www.moneycontrol.com/webinar';
            break;
        case 'Interview-Series':
            iframeUrl = 'https://www.moneycontrol.com/pro-interviews';
            break;
        case 'Cryptocurrency-News':
            iframeUrl = 'https://www.moneycontrol.com/cryptocurrency/';
            break;
        case 'Tools':
            iframeUrl = 'https://www.moneycontrol.com/mccode/tools/';
            break;
        case 'Home':
            iframeUrl = 'https://www.moneycontrol.com/mutualfundindia/';
            break;
        case 'Find-Fund':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/find-fund/';
            break;
        case 'Top-Ranked-Funds':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/best-funds/equity.html';
            break;
        case 'Performance-Tracker':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/performance-tracker/returns/large-cap-fund.html';
            break;
        case 'SIP-Performance-Tracker':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/performance-tracker/sip-returns/large-cap-fund.html';
            break;
        case 'ETF-Performance':
            iframeUrl = 'https://www.moneycontrol.com/mf/etf/';
            break;
        case 'NFO':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/new-fund-offers';
            break;
        case 'Top-Performing-Categories':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/performance-tracker/all-categories';
            break;
        case 'Learn':
            iframeUrl = 'https://www.moneycontrol.com/mutualfundindia/learn/';
            break;
        case 'Returns-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/mf/returns-calculator.php';
            break;
        case 'Lumpsum-SIP-Balancer':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/tools/lumpsum-sip-balancer';
            break;
        case 'Delay-Cost-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/mutual-funds/tools/delay-cost-calculator';
            break;
        case 'SIP-Return':
            iframeUrl = 'https://www.moneycontrol.com/mf/sipcalculator.php';
            break;
        case 'MF-Forum':
            iframeUrl = 'https://www.moneycontrol.com/mutualfundindia/mutual-fund-discussion-forum/';
            break;
        
        
        case 'new-30':
            iframeUrl = 'https://www.moneycontrol.com/mutual-fund/mc-30';
            break;
        case 'Personal-Fin':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/';
            break;
        case 'Investing':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/investing/';
            break;
        case 'Insurance':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/insurance/';
            break;
        case 'Banking':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/banking/';
            break;
        case 'Financial-Planning':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/financialplanning/';
            break;
        case 'Property':
            iframeUrl = 'https://www.moneycontrol.com/real-estate-property/';
            break;
        case 'Tooles':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/';
            break;
        case 'Videos':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/videos/';
            break;
        case 'Ask-Expert':
            iframeUrl = 'https://www.moneycontrol.com/forum-topics/ask-the-expert/';
            break;
        case 'Explainer':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/explainer/';
            break;
        case 'Income-Tax-Filing-Guide':
            iframeUrl = 'https://www.moneycontrol.com/news/business/personal-finance/income-tax-filing/';
            break;
        case 'NPS':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/nps-national-pension-scheme';
            break;
        case 'Fixed-Deposit-Comparison':
            iframeUrl = 'https://www.moneycontrol.com/fixed-deposit/fd-interest-rates/';
            break;
        case 'Fixed-Deposit-Interest-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/FD-calculator';
            break;
        case 'Corporate-Deposits':
            iframeUrl = 'https://www.moneycontrol.com/news/tags/company-fixed-deposits.html';
            break;
        case 'Income-Tax-Filing-Guides':
            iframeUrl = 'hhttps://www.moneycontrol.com/news/business/personal-finance/income-tax-filing/';
            break;
        case 'Income-Tax-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/emergency-fund-calculator.html';
            break;
        case 'Emergency-Fund-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/emergency-fund-calculator.html';
            break;
        case 'Personal-Loan':
            iframeUrl = 'https://www.moneycontrolpay.com/personal-loans/';
            break;
        case 'Car-Loan-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/carloan-emi-calculator.html';
            break;
        case 'Home-Loan-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/emi-calculator.html';
            break;
        case 'Education-Loan-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/education-loan-emi-calculator.html';
            break;
        case 'Credit-Card-Debit-Payoff-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/credit-card-debt-payoff-calculator.html';
            break;
        case 'Provident-Fund-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/provident-fund-calculator.html';
            break;
        case 'Assets-Allocation-Planning':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/asset-allocation-calculator.html';
            break;
        case 'Debt-Reduction-Planning':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/debt-reduction-plan-calculator.html';
            break;
        case 'Debt-Evaluation-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/debt-evaluation-calculator.html';
            break;
        case 'Current-Expense-Calculator':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/tools/current-expenses-calculator.html';
            break;
        case 'Real-estate':
            iframeUrl = 'https://www.moneycontrol.com/property-rates/mumbai';
            break;
        case 'New-30':
            iframeUrl = 'https://www.moneycontrol.com/mutual-fund/mc-30';
            break;
        case 'Health-Insurance-Ratings':
            iframeUrl = 'https://www.moneycontrol.com/personal-finance/health-insurance-ratings';
            break;
        case 'Gold-Price-Today':
            iframeUrl = 'https://www.moneycontrol.com/news/gold-rates-today/?utm_source=mcpf&utm_medium=desktop&utm_campaign=L1PF';
            break;
        case 'Forum':
            iframeUrl = 'https://mmb.moneycontrol.com/';
            break;
        
        case 'Forum-Topics':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/ask-the-expert/';
            break;
        case 'Ask-The-Eperts':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/ask-the-expert/';
            break;
        case 'Top-Boarders':
            iframeUrl = 'https://mmb.moneycontrol.com/top-boarders/';
            break;
        
        case 'Latest-Threads':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/latest-threads.html';
            break;
        case 'Stocks':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/stocks-1.html';
            break;
        case 'Index':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/market-view/index-246280.html';
            break;
        case 'Gold':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/commodity/gold-509313.html';
            break;
        case 'Personal-Finance':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/personal-finance-5.html';
            break;
        case 'Just-Posted':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/market-view/just-posted-509249.html';
            break;
        
        case 'Latest-Polls':
            iframeUrl = 'https://mmb.moneycontrol.com/poll';
            break;
        case 'Historical-Polls':
            iframeUrl = 'https://mmb.moneycontrol.com/poll/historical-polls/';
            break;
        case 'Market-Sentiments':
            iframeUrl = 'https://mmb.moneycontrol.com/poll/market-sentiments/';
            break;
        case 'FQAs':
            iframeUrl = 'https://mmb.moneycontrol.com/faq/';
            break;
        case 'Code -Of-Conduct':
            iframeUrl = 'https://mmb.moneycontrol.com/code-of-conduct/';
            break;
        case 'Feedback':
            iframeUrl = 'https://mmb.moneycontrol.com/forum-topics/feedback/about-mmb-160978.html';
            break;
        case 'Write-to-us':
            iframeUrl = 'https://www.moneycontrol.com/news/podcast/simply-save/';
            break;
        case 'Latest-Video':
            iframeUrl = 'https://www.moneycontrol.com/video-shows/';
            break;
        case 'Video-On-Demand':
            iframeUrl = 'https://www.moneycontrol.com/news/videos/';
            break;
        case 'Market-Wit-Santo-&-CJ':
            iframeUrl = 'https://www.moneycontrol.com/news/tags/mc-learn.html';
            break;
        case 'Morning-Trade':
            iframeUrl = 'https://www.moneycontrol.com/shows/morning-trade/';
            break;
        case 'Commodities-Chat-With-Manisha-Gupta':
            iframeUrl = 'https://www.moneycontrol.com/shows/commodities-chat-with-manisha-gupta/';
            break;
        case 'Let`s-Talk-Jobs':
            iframeUrl = 'https://www.moneycontrol.com/shows/lets-talk-jobs/';
            break;
        case 'The-Tenant':
            iframeUrl = 'https://www.moneycontrol.com/shows/the-tenant/';
            break;
        case 'Drive-Report':
            iframeUrl = 'https://www.moneycontrol.com/shows/drive-report/';
            break;
        case 'Bajar-Gupshup':
            iframeUrl = 'https://www.moneycontrol.com/news/';
            break;
        case 'Bits-To-Billions':
            iframeUrl = 'https://www.moneycontrol.com/shows/bits-to-billions/';
            break;
        
        case 'Podcast-On-Demand':
            iframeUrl = 'https://www.moneycontrol.com/india/stockpricequote/Podcast-On-Demand';
            break;
        case 'The-Market-Podcast':
            iframeUrl = 'https://www.moneycontrol.com/news/tags/podcast.html';
            break;
        case 'The-Market-on-Dalal-Street':
            iframeUrl = 'https://www.moneycontrol.com/news/podcast/the-week-on-dalal-street/';
            break;
        case 'Market-Minutes':
            iframeUrl = 'https://www.moneycontrol.com/news/podcast/market-minutes/';
            break;
        case 'Special-Podcast':
            iframeUrl = 'https://www.moneycontrol.com/news/';
            break;
        case 'Simply-Save':
            iframeUrl = 'https://www.moneycontrol.com/news/podcast/simply-save/';
            break;
        case 'Policy-Talks':
            iframeUrl = 'https://www.moneycontrol.com/features/';
            break;
        case 'Hindi':
            iframeUrl = 'https://hindi.moneycontrol.com/tv/';
            break;
        case 'Gujarati':
            iframeUrl = 'https://gujarati.moneycontrol.com/tv/';
            break;
        // Add more cases as needed
        default:
            iframeUrl = 'https://www.moneycontrol.com/india/stockpricequote';
            break;
    }

    return (
        <div className="app">
            <iframe
                className="fullscreen-iframe"
                src={iframeUrl}
                title="Full Screen Not Load"
                allowFullScreen
            />
        </div>
    );
}

export default Part;
