import React, { useEffect, useState } from "react";
import { useLocation,useParams ,useNavigate,Link} from "react-router-dom";
import Button from '@mui/material/Button';
import { API } from "../../service/api";

function PortfolioSection() {
// This gives you access to the URL parameter
// const { portfoliochange } = useParams();
const location = useLocation();
const { portfoliochange: initialPortfolioChange } = useParams();
const [portfoliochange, setPortfolioChange] = useState(initialPortfolioChange);
const [selectedDate, setSelectedDate] = useState(null);
// State to store the fetched stock data
const [weekstockData, setweekstockData] = useState([]);
const navigate = useNavigate();

const [stockData, setStockData] = useState([]);



const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
        stockName: params.get('stockName') // Extract stockName from query parameters
    };
};

const { stockName } = getQueryParams();


    useEffect(() => {
        
        // Fetch the stock data using the stockName from the URL
        const fetchData = async () => {
        try {
            // Example API call
            const response = await API.getstockByname(stockName);
            if (response.isSuccess) {
            setStockData(response.data);
            }
        } catch (error) {
            console.error("Error fetching stock data:", error);
            if (error.response && error.response.status === 403) {
                // If error status is 403, navigate to the /login route
                navigate("/login");
            }
        }
        };
        fetchData();
        // Fetch data whenever the stockName parameter changes
    }, [stockName,navigate]);
// const [livePriceData, setLivePriceData] = useState({});

// async function fetchAndDisplayLivePriceData() {
//     try {
//         const response = await fetch(urlliveData);
//         if (!response.ok) {
//             throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setLivePriceData(data);
//     } catch (error) {
//         console.error('Error fetching live price data:', error);
//     }
// }

// useEffect(() => {
//     fetchAndDisplayLivePriceData();
    
// }, []);
// const urlliveData = `https://api-mintgenie.livemint.com/api-gateway/fundamental/markets-data/live-price/v2?exchangeCode=BSE&tickerId=${tickerID}`;


useEffect(() => {
    // Fetch the stock data using the weekSignal from the URL
    const fetchData = async () => {
    try {
        // Example API call
        const response = await API.getstockByallportfolio(portfoliochange);
        if (response.isSuccess) {
        setweekstockData(response.data);
        }
    } catch (error) {
        console.error("Error fetching stock data:", error);
        if (error.response && error.response.status === 403) {
            // If error status is 403, navigate to the /login route
            navigate("/login");
        }
    }
    };
    fetchData();
    // Fetch data whenever the stockName parameter changes
}, [portfoliochange,navigate]);

useEffect(() => {
    setPortfolioChange(initialPortfolioChange);
}, [initialPortfolioChange]);



const handleDateClick = (date) => {
    setSelectedDate(date);
};

const handleButtonClick = (newValue) => {
    setPortfolioChange(newValue);
};

    


return (
    // <div>
    // {/* <h1>Stock Name: {stockName}</h1> */}
    // {weekstockData ?
    //     weekstockData.map((stock, index) => (
    //     <div key={index}>
    //         <h1>Stock Name: {stock.name}</h1>
    //         <p>Ticker: {stock.ticker}</p>
    //         {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
    //         {stock.target !== '#N/A' && <p>Target: {stock.target}</p>}
    //         {stock.averageTarget !== '#N/A' && <p>AverageTarget: {stock.averageTarget}</p>}
            
    //         {stock.buyStatic !== '#N/A' && <p>Buy Predict By Experts: {stock.buyStatic}</p>}
    //         {stock.sellStatic !== '#N/A' && <p>Sell Predict By Experts: {stock.sellStatic}</p>}
    //         {stock.parameterRate && !stock.parameterRate.includes('#N/A') && (
    //             <p>Parameter Rate: {stock.parameterRate}</p>
    //         )}
    //         {stock.weekComplate !== '#N/A' && <p>This Week Percentage: {stock.weekComplate}%</p>}
    //         {stock.finalGP !== '#N/A' && stock.finalGP !== '' && <p>Final By Experts: {stock.finalGP}</p>}
    //         {stock.nextMonth !== '#N/A' && <p>This Month : {stock.nextMonth}</p>}
    //         <Link to={`/stock/?stock=${stock.ticker}&stockphase=${stock.ticker}`}>
    //         <Button>More About</Button>
    //         </Link>
            
    //         {/* Render other properties as needed */}
    //     </div>
    //     ))
    // : (<p>Loading...</p>)}{/* Other component content */}
    // </div>
    <>
    
    <div>
            {weekstockData.slice().reverse().map(item => (
    <div key={item.date}>
        <h3 onClick={() => handleDateClick(item.date)}>{`Ai Mind on Date: ${new Date(item.date).toISOString().split('T')[0]}`}</h3>
        {selectedDate === item.date && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15%' }}>
              <div>
                <h4>Buy:</h4>
                <ul>
                    {item.TodayDate['Buy'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Buy Today Ai Mind</h3> */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                
                                </Link>
                                
                                <p>Stock Name: {stockItem.name}</p>
                                <p>Buy Price (Maximum): {stockItem.price}</p> 
                                {/* <p>Target: {stockItem.target}</p>*/}
                                <p>Ticker: {stockItem.ticker}</p>
                                {/* <p>Demand: {stockItem.buyStatic}</p> */}
                                
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div>
                <div>
                <h4>Hold,BTST:</h4>
                <ul>
                    {item.TodayDate['Hold'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Hold Today Ai Mind</h3> */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                </Link>
                                <p>Stock Name: {stockItem.name}</p>
                                {/* <p>Stock Price: {stockItem.price}</p> 
                                <p>Target: {stockItem.target}</p>*/}
                                <p>Ticker: {stockItem.ticker}</p>
                                <p>Demand: {stockItem.buyStatic}</p>
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div>
                <div>
                <h4>Exitbuy:</h4>
                <ul>
                    {item.TodayDate['exitbuy'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Exitbuy Today Ai Mind</h3> */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                </Link>
                                <p>Stock Name: {stockItem.name}</p>
                                {/* <p>Exit Opening Price(Minimum): {stockItem.price}</p> 
                                <p>Target: {stockItem.target}</p>*/}
                                <p>Ticker: {stockItem.ticker}</p>
                                <p>Demand: {stockItem.buyStatic}</p>
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div>
                </div>
            </div>
        )}
    </div>
))}

            </div>
        
    </>

// <div>
//     <div key={weekstockData.key} >
//         {/* Print buy data objects */}
//         <h3>{weekstockData.key}</h3>
//         {portfoliochange === 'buy' && (
//             <ul>
//                 {weekstockData.TodayDate.Buy.map((buyItem, index) => (
//                     <li key={index}>
//                         <p>Stock Name: {buyItem.name}</p>
//                         <p>Stock Price: {buyItem.price}</p>
//                         <p>Buying Price: {buyItem.ticker}</p>
//                         <p>Selling Price: {buyItem.buyStatic}</p>
//                         <p>Target: {buyItem.target}</p>
//                         {/* Add more properties as needed */}
//                     </li>
//                 ))}
//             </ul>
//         )}

//         {portfoliochange === 'hold' && (
//             <ul>
//                 {weekstockData.TodayDate.Hold.map((holdItem, index) => (
//                     <li key={index}>
//                         <p>Stock Name: {holdItem.name}</p>
//                         <p>Stock Price: {holdItem.price}</p>
//                         <p>Buying Price: {holdItem.ticker}</p>
//                         <p>Selling Price: {holdItem.buyStatic}</p>
//                         <p>Target: {holdItem.target}</p>
//                         {/* Add more properties as needed */}
//                     </li>
//                 ))}
//             </ul>
//         )}

//         {portfoliochange === 'sell' && (
//             <ul>
//                 {weekstockData.TodayDate.exitbuy.map((sellItem, index) => (
//                     <li key={index}>
//                         <p>Stock Name: {sellItem.name}</p>
//                         <p>Stock Price: {sellItem.price}</p>
//                         <p>Buying Price: {sellItem.ticker}</p>
//                         <p>Selling Price: {sellItem.buyStatic}</p>
//                         <p>Target: {sellItem.target}</p>
//                         {/* Add more properties as needed */}
//                     </li>
//                 ))}
//             </ul>
//         )}
//     </div>
// </div>
);
}

export default PortfolioSection;

                // <h3>Buy Before Hold Today Ai Mind</h3>
                // <p>Stock Name: {buyItem.name}</p>
                // <p>Stock Price: {buyItem.price}</p>
                // <p>Ticker: {buyItem.ticker}</p>
                // <p>Demand: {buyItem.buyStatic}</p>
                // <p>Target: {buyItem.target}</p>
                // <h3>Buy Before Hold Today Ai Mind</h3>
                // <p>Stock Name: {buyItem.name}</p>
                // <p>Stock Price: {buyItem.price}</p>
                // <p>Ticker: {buyItem.ticker}</p>
                // <p>Demand: {buyItem.buyStatic}</p>
                // <p>Target: {buyItem.target}</p>
                // <h3>Buy Before Exit Today Ai Mind</h3>
                // <p>Stock Name: {buyItem.name}</p>
                // <p>Exit Opening Price: {buyItem.price}</p>
                // <p>Ticker: {buyItem.ticker}</p>
                // <p>Demand: {buyItem.buyStatic}</p>