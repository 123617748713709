import React, { useEffect, useState } from "react";
import { useLocation,useParams, useNavigate, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { API } from "../../service/api";

function AboutWorld() {
    const location = useLocation();
    const { portfoliochange: initialPortfolioChange } = useParams();
    const [portfoliochange, setPortfolioChange] = useState(initialPortfolioChange);
    const [weekstockData, setWeekstockData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();



    const [stockData, setStockData] = useState([]);



const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
        stockName: params.get('stockName') // Extract stockName from query parameters
    };
};

const { stockName } = getQueryParams();


    useEffect(() => {
        
        // Fetch the stock data using the stockName from the URL
        const fetchData = async () => {
        try {
            // Example API call
            const response = await API.getstockByname(stockName);
            if (response.isSuccess) {
            setStockData(response.data);
            }
        } catch (error) {
            console.error("Error fetching stock data:", error);
            if (error.response && error.response.status === 403) {
                // If error status is 403, navigate to the /login route
                navigate("/login");
            }
        }
        };
        fetchData();
        // Fetch data whenever the stockName parameter changes
    }, [stockName,navigate]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await API.getstockByallworld(portfoliochange);
                if (response.isSuccess) {
                    setWeekstockData(response.data);
                }
            } catch (error) {
                console.error("Error fetching stock data:", error);
                if (error.response && error.response.status === 403) {
                    navigate("/login");
                }
            }
        };
        fetchData();
    }, [portfoliochange, navigate]);

    const handleDateClick = (date) => {
        setSelectedDate(date);
    };

    const handleButtonClick = (newValue) => {
        setPortfolioChange(newValue);
    };

    return (
        <>
            
            <div>
            {weekstockData.slice().reverse().map(item => (
    <div key={item.date}>
        <h3 onClick={() => handleDateClick(item.date)}>{`Ai Mind on Date: ${new Date(item.date).toISOString().split('T')[0]}`}</h3>
        {selectedDate === item.date && (
            <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15%' }}>
            <div>
                <h4>Buy:</h4>
                <ul>
                    {item.TodayDate['Buy'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Buy Today Ai Mind</h3> */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                </Link>
                                <p> {stockItem.ticker}</p>
                                <p>Stock Price: {stockItem.price}</p>
                                {/* <p>Buying Price: {stockItem.ticker}</p>
                                <p>Selling Price: {stockItem.buyStatic}</p>
                                <p>Target: {stockItem.target}</p> */}
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div>
                <div>
                <h4>Hold:</h4>
                <ul>
                    {item.TodayDate['Hold'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Hold Today Ai Mind</h3> */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                </Link>
                                <p> {stockItem.ticker}</p>
                                <p>Stock Price: {stockItem.price}</p>
                                {/* <p>Buying Price: {stockItem.ticker}</p>
                                <p>Selling Price: {stockItem.buyStatic}</p>
                                <p>Target: {stockItem.target}</p> */}
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div>
                <div>
                <h4>Exitbuy:</h4>
                <ul>
                    {item.TodayDate['exitbuy'].map((stockItem, index) => (
                        <li key={index}>
                            <div>
                                {/* <h3>Exitbuy Today Ai Mind</h3>  */}
                                <Link to={`/stock/?stock=${stockItem.ticker}&stockphase=${stockItem.ticker}`}>
                                <Button>
                                <p>{stockItem.name}</p>
                                </Button>
                                </Link>
                                <p> {stockItem.ticker}</p>
                                <p>Stock Price: {stockItem.price}</p>
                                {/* <p>Buying Price: {stockItem.ticker}</p>
                                <p>Selling Price: {stockItem.buyStatic}</p>
                                <p>Target: {stockItem.target}</p>  */}
                                <Link to={`?stockName=${stockItem.ticker}`}>
                                <Button>
                                <p>View Live Price</p>
                                </Button>
                                
                                </Link>
                                <div>

                                {stockData && stockItem.ticker === stockName ? (
                                    stockData.map((stock, index) => (
                                        <div key={index}>
                                            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}
                                </div>
                            </div>
                            
                        </li>
                    ))}
                </ul>
                </div> 
                </div>
            </div>
        )}
    </div>
))}

            </div>
        </>
    );
}

export default AboutWorld;

// {portfoliochange === 'hold' && (
//   item.TodayDate.Hold.map((holdItem, index) => (
//     <li key={index}>
//       <div>
//         <h3>Hold Today Ai Mind</h3>
//         <p>Stock Name: {holdItem.name}</p>
//         <p>Stock Price: {holdItem.price}</p>
//         <p>Buying Price: {holdItem.ticker}</p>
//         <p>Selling Price: {holdItem.buyStatic}</p>
//         <p>Target: {holdItem.target}</p>
//         // Add more properties as needed 
//       </div>
//       <div>
//         <Link to={`/stock/?stock=${holdItem.ticker}&stockphase=${holdItem.ticker}`}>
//           <Button>More About</Button>
//         </Link>
//       </div>
//     </li>
//   ))
// )}
// {portfoliochange === 'sell' && (
//   item.TodayDate.exitbuy.map((sellItem, index) => (
//     <li key={index}>
//       <div>
//         <h3>Sell Today Ai Mind</h3>
//         <p>Stock Name: {sellItem.name}</p>
//         <p>Stock Price: {sellItem.price}</p>
//         <p>Buying Price: {sellItem.ticker}</p>
//         <p>Selling Price: {sellItem.buyStatic}</p>
//         <p>Target: {sellItem.target}</p>
//         //Add more properties as needed 
//       </div>
//       <div>
//         <Link to={`/stock/?stock=${sellItem.ticker}&stockphase=${sellItem.ticker}`}>
//           <Button>More About</Button>
//         </Link>
//       </div>
//     </li>
//   ))
// )}