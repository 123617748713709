import React from 'react';
import './AiresourceMF.css'; // assuming you have a CSS file for styling
// https://portal.tradebrains.in/
// https://www.livemint.com/
function AiresourceMF() {
return (
    <div className="app">
    <iframe
        className="fullscreen-iframe"
        src="https://portal.tradebrains.in/"
        sandbox="allow-same-origin allow-scripts"
        width="100%" 
        height="500px"
        title="Full Screen Not Load"
        allowFullScreen
    />
    </div>
);
}

export default AiresourceMF;
