import React, { useEffect, useState } from "react";
import { useParams ,useNavigate,Link} from "react-router-dom";
import Button from '@mui/material/Button';
import { API } from "../../service/api";

function AboutWeek() {
// This gives you access to the URL parameter
const { weekSignal } = useParams();

// State to store the fetched stock data
const [weekstockData, setweekstockData] = useState([]);
const navigate = useNavigate();
useEffect(() => {
    // Fetch the stock data using the weekSignal from the URL
    const fetchData = async () => {
    try {
        // Example API call
        const response = await API.getstockByweeksignal(weekSignal);
        if (response.isSuccess) {
        setweekstockData(response.data);
        }
    } catch (error) {
        console.error("Error fetching stock data:", error);
        if (error.response && error.response.status === 403) {
            // If error status is 403, navigate to the /login route
            navigate("/login");
        }
    }
    };
    fetchData();
    // Fetch data whenever the stockName parameter changes
}, [weekSignal,navigate]);

return (
    <div>
    {/* <h1>Stock Name: {stockName}</h1> */}
    {weekstockData ?
        weekstockData.map((stock, index) => (
        <div key={index}>
            <h1>Stock Name: {stock.name}</h1>
            <p>Ticker: {stock.ticker}</p>
            {stock.price !== '#N/A' && <p>Price: {stock.price}</p>}
            {stock.target !== '#N/A' && <p>Target: {stock.target}</p>}
            {stock.averageTarget !== '#N/A' && <p>AverageTarget: {stock.averageTarget}</p>}
            
            {stock.buyStatic !== '#N/A' && <p>Buy Predict By Experts: {stock.buyStatic}</p>}
            {stock.sellStatic !== '#N/A' && <p>Sell Predict By Experts: {stock.sellStatic}</p>}
            {stock.parameterRate && !stock.parameterRate.includes('#N/A') && (
                <p>Parameter Rate: {stock.parameterRate}</p>
            )}
            {stock.weekComplate !== '#N/A' && <p>This Week Percentage: {stock.weekComplate}%</p>}
            {stock.finalGP !== '#N/A' && stock.finalGP !== '' && <p>Final By Experts: {stock.finalGP}</p>}
            {stock.nextMonth !== '#N/A' && <p>This Month : {stock.nextMonth}</p>}
            <Link to={`/stock/?stock=${stock.ticker}&stockphase=${stock.ticker}`}>
            <Button>More About</Button>
            </Link>
            
            {/* Render other properties as needed */}
        </div>
        ))
    : (<p>Loading...</p>)}{/* Other component content */}
    </div>
);
}

export default AboutWeek;
